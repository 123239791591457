<template>
    <v-card flat color="#F9FAFB" style="color: rgba(0, 0, 0, 0.87)">
        <v-card-title class="pl-8 text-capitalize mb-1 pb-0"
            >Transfer Summary</v-card-title
        >

        <v-card-text style="color: rgba(0, 0, 0, 0.87)">
            <v-container>
                <HbInfoCard
                    class="mb-4"
                    type="contact"
                    :title="computedContactTitle"
                    :is-military="selectedTenant.Military && selectedTenant.Military.active && selectedTenant.Military.active == 1"
                    :status="selectedTenant.status ? selectedTenant.status : undefined"
                    :phone="computedContactPhone | formatPhone"
                    :phone-is-verified="computedContactPhoneVerified"
                    :email="selectedTenant.email ? selectedTenant.email : undefined"
                    :email-is-verified="selectedTenant.email_verified"
                    :contact-address="computedContactAddress"
                    :access-code="computedAccessCode"
                    :contact-is-business="selectedTenant.rent_as_business"
                    @click="viewEntity('contact', selectedTenant.id)"
                />
                <v-expansion-panels
                    v-if="configuration_unit.id"
                    v-model="panel"
                    multiple
                    class="hb-expansion-panel pb-4"
                >
                    <v-expansion-panel class="transfer-custom-expansion-panel-elevation">
                        <v-expansion-panel-header
                            class="hb-default-font-size pt-3 pb-4 px-6"
                        >
                            <v-col cols="12" class="pa-0 ma-0">
                                <hb-link
                                    color="secondary"
                                    @click="
                                        viewEntity(
                                            'unit',
                                            configuration_unit.id
                                        )
                                    "
                                >
                                    <div class="d-flex align-center">
                                        <div class="pr-1">
                                            <HbUnitIcon
                                                v-if="configuration_unit"
                                                small
                                                color="#306FB6"
                                                :type="getUnitTypeLabel(configuration_unit)"
                                            />
                                          
                                        </div>
                                        <div class="font-weight-medium mt-1">
                                            #{{ configuration_unit.number }}
                                        </div>
                                    </div>
                                </hb-link>
                                <div class="pt-1">
                                    <span v-if="configuration_unit.label">{{ configuration_unit.label }}</span><span v-else>{{ configuration_unit.label }}</span>,
                                    {{ configuration_unit.type | capitalize }}
                                </div>
                            </v-col>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content
                            class="px-6 pt-5 ma-0"
                            elevation="0"
                        >
                            <v-row class="ma-0 pb-3" @click="showNotificationModal('edit_bill_day')">
                                <v-select
                                    :disabled="step == 3 || step == 4 || !this.hasPermission('edit_bill_day_transfer_process')"
                                    :items="daysOfMonth"
                                    item-text="name"
                                    item-value="id"
                                    v-model="transferData.bill_day"
                                    @change="billingOnChanged"
                                    label="Billing On"
                                    id="bill_day"
                                    name="bill_day"
                                    data-vv-scope="transfer"
                                    dense
                                    flat
                                >
                                    <template v-slot:selection="{ item, index }">
                                        {{ item.name }} of the month
                                    </template>
                                    <template v-slot:item="{ item, index }">
                                        {{ item.name }}
                                    </template>
                                </v-select>
                            </v-row>
                            <v-row class="ma-0 pb-3">
                                <v-select
                                    v-if="isPaymentCycleAllowed && payment_cycle_options && payment_cycle_options.length"
                                    :disabled="step == 3 || step == 4"
                                    :items="payment_cycle_options"
                                    item-text="label"
                                    item-value="label"
                                    :clearable="true"
                                    v-model="transferData.payment_cycle"
                                  
                                    label="Next Payment Cycle"
                                    id="Payment Cycle"
                                    name="Payment Cycle"
                                    data-vv-scope="transfer"
                                    dense
                                    flat
                                    placeholder="Choose Payment Cycle"
                                ></v-select>
                            </v-row>
                            
                            <v-row v-if="!leaseData.payment_cycle" class="ma-0 pb-3">
                                <v-select
                                    v-if="isBillingCycleAllowed && !payment_cycle_options?.length && leaseData?.BillingCycleOptions?.length"
                                    :disabled="step == 3 || step == 4"
                                    :items="leaseData.BillingCycleOptions"
                                    v-model="transferData.billing_cycle_id"
                                    @change="billingOnChanged"
                                    label="Select New Billing Cycle"
                                    id="billing_cycle"
                                    name="billing_cycle"
                                    item-value="billing_cycle_id"
                                    :clearable='true'
                                    placeholder="Select New Billing Cycle"
                                    data-vv-scope="transfer"
                                    dense
                                    flat
                                >
                                    <template v-slot:selection="{ item }">
                                        {{ item.value | formatMonth }}
                                    </template>
                                    <template v-slot:item="{ item }">
                                        {{ item.value | formatMonth }}
                                    </template>
                                </v-select>
                            </v-row>

                            <v-row class="ma-0 pb-3">
                                <v-select
                                    :disabled="step == 3 || step == 4"
                                    :items="promotionsArray"
                                    item-text="name"
                                    item-value="id"
                                    v-model="transferData.promotion"
                                    @change="promotionChanged"
                                    label="Promotion"
                                    id="promotion"
                                    name="promotion"
                                    data-vv-scope="transfer"
                                    dense
                                    flat
                                    v-if="!transferData?.billing_cycle_id"
                                    return-object
                                ></v-select>
                            </v-row>
                            <v-row v-if="!leaseData.payment_cycle" class="ma-0 pb-3">
                                <v-select
                                    :disabled="step == 3 || step == 4"
                                    :items="discountsArray"
                                    item-text="name"
                                    item-value="id"
                                    v-model="transferData.discount_id"
                                    @change="productChanged"
                                    label="Discount"
                                    id="discount"
                                    name="discount"
                                    data-vv-scope="transfer"
                                    dense
                                    flat
                                ></v-select>
                            </v-row>
                            <v-row class="ma-0">
                                <v-select
                                    :disabled="step == 3 || step == 4 || paymentMethodsData.length == 1 || transferData.disable_autopay"
                                    :items="paymentMethodsData"
                                    item-value="id"
                                    v-model="transferData.autopay_id"
                                    @change="autopayChanged"
                                    label="AutoPay"
                                    id="autpay"
                                    name="autopay"
                                    data-vv-scope="transfer"
                                    dense
                                    flat
                                >
                                    <template v-slot:selection="data">
                                        <slot name="selection" :item="data.item"
                                            ><span v-if="data.item.id != ''">XXXX-{{ data.item.card_end }}</span><span v-else>{{data.item.name}}</span></slot
                                        >
                                    </template>

                                    <template v-slot:item="data">
                                        <slot name="item" :item="data.item"
                                            ><span v-if="data.item.id != ''">XXXX-{{ data.item.card_end }}</span><span v-else>{{data.item.name}}</span></slot
                                        >
                                    </template>
                                </v-select>

                            </v-row>
                            <v-row class="ma-0 pb-3" @click="showNotificationModal('no_tax_rent')">
                                <HbCheckbox 
                                    :disabled="step == 3 || step == 4 || !hasPermission('no_tax_rent')"
                                    class="mt-2"
                                    v-model="form.tax_exempt" 
                                    @change="taxExemptChanged" 
                                    label="Mark rent as tax exempt" /> 
                            </v-row>
                            <v-row v-if="form.tax_exempt" class="ma-0 pb-3">                      
                                <HbTextarea
                                    :disabled="step == 3 || step == 4 || !hasPermission('no_tax_rent')"
                                    full
                                    box
                                    :rows="3"
                                    :maxlength="255"
                                    class="mt-2 col-12"
                                    ref="taxExemptDesc"
                                    data-vv-name="transfer"
                                    v-validate="'required|max:255'"
                                    data-vv-as="Rent Tax Exempt Reason" 
                                    @blur="taxExemptDescriptionChanged"
                                    v-model="form.tax_exempt_description"
                                    placeholder="Reason for status change"                  
                                    :error="errors.has('tax_exempt_description')"
                                     />                       
                            </v-row>
                            <v-row class="ma-0 pb-3" v-if = "transferData.isFreeInsuranceEnabled">
                                <HbCheckbox 
                                    :disabled="step == 3 || step == 4" 
                                    class="mt-2"
                                    v-model="form.isFreeInsuranceSelected" 
                                    @change = "freeInsuranceSelected"
                                    label="Waive off insurance amount" /> 
                            </v-row>
                            <v-row class="ma-0 pb-3 pt-4">
                                <v-select
                                    :disabled="step == 3 || step == 4 || transferData.disable_insurance"
                                    :items="insurances"
                                    item-text="name"
                                    item-value="id"
                                    v-model="transferData.insurance_id"
                                    @change="insuranceChanged"
                                    label="Coverage/Protection"
                                    id="insurance"
                                    name="insurance"
                                    data-vv-scope="transfer"
                                    dense
                                    flat
                                ></v-select>
                            </v-row>
                            <v-row v-if="insurances.length && insurances[0].insurance_type === 'table_rate' && insurances[0].id == transferData.insurance_id" class="ma-0 pb-2">
                                <table-rate-insurance
                                    :insurances="[insurances[0]]"
                                    :isSelected="!transferData.disable_insurance"
                                    :fullWidth="true"
                                    :showRadioButton="false"
                                    :tiersSmallFont="true"
                                    :coverageAmount="form.table_rate_coverage_amount"
                                    @updateCoverageValue="updateCoverageValue"
                                />
                            </v-row>
                            <span v-for="(fee, index) in transferData.productFees" :key="index">
                                <v-row class="ma-0 pb-3" v-if="fee && (fee.product_id || fee.product_id == '')">
                                    <v-select
                                        :disabled="step == 3 || step == 4"
                                        :items="fees"
                                        item-text="name"
                                        item-value="id"
                                        v-model="fee.product_id"
                                        @change="productChanged"
                                        label="Fees"
                                        placeholder="Select"
                                        id="fee"
                                        name="fee"
                                        data-vv-scope="transfer"
                                        dense
                                        flat
                                        :clearable="index > 0"
                                        @click:clear="removeFee(index)"
                                    ></v-select>
                                </v-row>
                            </span >
                            <AccessHourSelection
                                v-if="accessAreasEnabled && !hideHoursSelection"
                                ref="access-hour"
                                v-model="selectedHours"
                                :property-id="property_id"
                                :area-id="configuration_unit.area_id"
                                :contact-id="contact.id"
                                :hide-selection.sync="hideHoursSelection"
                                use-v-select
                            />
                            <v-row class="ma-0 pb-3" v-if="(step == 1 || step == 2) && transferData.productFees.length && transferData.productFees[0].product_id ">
                                <hb-link class="hb-default-font-size" @click="transferData.productFees.push({product_id: ''})">+ Add New Fee</hb-link>
                            </v-row>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
                <div class="px-2 hb-default-font-size">
                    <v-row>
                        <v-col class="pb-0">Transfer Out Balance</v-col>
                        <span
                            class="content-align-end pa-3 pb-0 font-weight-medium"
                            >-{{ currentRefund | formatMoney }}</span
                        >
                    </v-row>
                    <v-row>
                        <v-col class="text-caption py-0">
                            <HbUnitIcon
                                v-if="leaseData.Unit"
                                    small
                                    color="#101318"
                                    :type="getUnitTypeLabel(leaseData.Unit)"
                                />
                         
                            {{leaseData.Unit.number}}
                        </v-col>
                    </v-row>
                    <span v-show="!transferCalculation">
                            <loader color="#00b2ce" size="20px" class="inline-loader"></loader>
                    </span>
                    <v-row v-if="configuration_unit.id && summary_invoice && transferCalculation">
                        <v-col class="pb-0">Transfer In Balance</v-col>
                        <span
                            class="content-align-end pa-3 pb-0 font-weight-medium"
                            v-if="this.invoices.length"
                            >{{summary_invoice.total_payments | formatMoney}}</span
                        >
                        <span
                            class="content-align-end pa-3 pb-0 font-weight-medium"
                            v-else
                            >{{summary_invoice.balance | formatMoney}}</span
                        >
                    </v-row>
                    <v-row v-if="configuration_unit.id && summary_invoice && transferCalculation">
                        <v-col class="text-caption pt-0">
                            <HbUnitIcon
                                v-if="configuration_unit"
                                    small
                                    color="#101318"
                                    :type="getUnitTypeLabel(configuration_unit)"
                                />
                         
                             {{configuration_unit.number}} ({{summary_invoice.period_start | formatDate}} - {{summary_invoice.period_end | formatDate}})
                        </v-col>
                    </v-row>
                    <div class="border pt-4" v-if="configuration_unit.id  && summary_invoice && transferCalculation"></div>
                    <v-row
                        v-if="configuration_unit.id  && summary_invoice && transferCalculation"
                        class="font-weight-medium"
                    >
                        <v-col>
                            Transfer Balance
                        </v-col>
                        <span class="content-align-end pa-3">{{transfer_balance_due | formatMoney}}</span>
                    </v-row>
                </div>
            </v-container>
        </v-card-text>

        <payments-notification-modal
            v-model="notificationModal"
            :notificationType="notificationModalType"
        />
    </v-card>
</template>

<script type="text/babel" >
import moment from "moment";
import { mapGetters,mapMutations } from "vuex";
import Loader from "../../assets/CircleSpinner.vue"
import { EventBus } from "../../../EventBus";
import PaymentsNotificationModal from '../../payments/PaymentsNotificationModal.vue';
import AccessHourSelection from "../../access_hour_selection/AccessHourSelection.vue";
import TableRateInsurance from "../../table_rate_insurance/Index.vue";

export default {
    name: "TransferSummary",
    components: {
		Loader,
        PaymentsNotificationModal,
        AccessHourSelection,
        TableRateInsurance,
    },
    data() {
        return {
            hideHoursSelection: false,
            selectedHours: {},
            promotionsArray: [],
            discountsArray: [],
            payment_cycle_options:[],
            autopayList: [{ id: "", name: "XXXX-1111" }],
            panel: [0],
            insurance_exp_month: '',
            insurance_exp_year: '',
            notificationModalType:'',
            form: {
                id: null,
                unit_id: '',
                rent: 0,
                security_deposit: 0,
                type: '',
                start_date: '',
                end_date: '',
                bill_day: 1,
                discount_id: "",
                promotion: { id: "", name: "No Promo" },
                promotions: [],
                payment_cycle: '',
                autopay_id: '',
                insurance_id: '',
                table_rate_coverage_amount:'',
                products: [],
                fee_id: '',
                send_invoice: 1,
                notes: '',
                status: '',
                decline_insurance: false,
                baseRentChanged: false,
                billing_cycle_id: null,
                tax_exempt: 0,
                tax_exempt_description: null,
                isFreeInsuranceSelected: false,
            },
            monthList:[
                { text:'January', value:1 },
                { text:'February', value:2 },
                { text:'March', value:3 },
                { text:'April', value:4 },
                { text:'May', value:5 },
                { text:'June', value:6 },
                { text:'July', value:7 },
                { text:'August', value:8 },
                { text:'September', value:9 },
                { text:'October', value:10 },
                { text:'November', value:11 },
                { text:'December', value:12 },
            ],
            yearList: [
                { text: '2020', value: '2020'},
                { text: '2021', value: '2021'},
                { text: '2022', value: '2022'},
                { text: '2023', value: '2023'},
                { text: '2024', value: '2024'},
                { text: '2025', value: '2025'},
                { text: '2026', value: '2026'},
                { text: '2027', value: '2027'},
                { text: '2028', value: '2028'},
                { text: '2029', value: '2029'},
                { text: '2030', value: '2030'},
            ],
            tenant:{},
            transferCalculation:true,
            notificationModal: false
        };
    },
    props: ["leaseData", "contact", "unitIn", "property_id", "paymentMethodsData", "transferData", "step", "currentRefund", "insurances", "fees", "access", "spaceAccess", "isSpaceAccessCodesVendor", "selectedHoursId", "accessAreasEnabled"],
    computed: {
        ...mapGetters({
            discounts: "revManStore/getPropertyDiscounts",
            promotions: "revManStore/getActivePromotions",
            configuration_unit: "onBoardingStore/configuration_unit",
            configuration_lease:  'onBoardingStore/configuration_lease',
            configuration_invoices: 'onBoardingStore/configuration_invoices',
            invoices: 'onBoardingStore/invoices',
            contacts: 'onBoardingStore/contact',
            lease: "onBoardingStore/lease",
            services: "onBoardingStore/services",
            templateFees: "onBoardingStore/templateFees",
            template: 'onBoardingStore/template',
            pCycleOptions: 'onBoardingStore/pCycleOptions',
            hasPermission: 'authenticationStore/rolePermission',
            isPaymentCycleAllowed: 'propertiesStore/isPaymentCycleAllowed',
            isBillingCycleAllowed: 'propertiesStore/isBillingCycleAllowed',
        }),
        unit_template(){
            return this.template[this.configuration_unit.id];
        }, 
        getFeeProducts() {
            return JSON.parse(JSON.stringify(this.transferData.productFees));
        },
        selectedTenant: {
            get() {
                return this.tenant;
            },
            set(value) {
                this.tenant = value;
            }
        },
        transfer_balance_due() {
            if(this.currentRefund && this.summary_invoice && this.currentRefund > 0) {
                let balance_due = this.invoices.length ? this.summary_invoice.total_payments - this.currentRefund : this.summary_invoice.balance - this.currentRefund;
                return balance_due;
            } else {
                return  this.summary_invoice ? this.summary_invoice.balance : 0;
            }
        },
        summary_data(){
            return this.configuration_unit.id ? this.configuration_lease : this.form;
        },
        bill_day() {
            return this.configuration_lease ? this.configuration_lease.bill_day : this.form.bill_day;
        },
        summary_invoice(){
            let invoices = []
            invoices = this.invoices.length ? this.invoices : this.configuration_invoices;

            let summary_invoice;
            if(invoices && invoices.length){                   
            for(const invoice of invoices){
                if(!summary_invoice){
                    summary_invoice = JSON.parse(JSON.stringify(invoice));
                    continue;
                }

                for(const line of invoice.InvoiceLines){
                    let index = summary_invoice.InvoiceLines.findIndex(il => il.Product.id == line.Product.id);
                    if(index >= 0){
                        if(moment(line.end_date, 'YYYY-MM-DD').isAfter(moment(summary_invoice.InvoiceLines[index].end_date, 'YYYY-MM-DD'))){
                            summary_invoice.InvoiceLines[index].end_date = line.end_date;
                        }
                        summary_invoice.InvoiceLines[index].cost += line.cost;
                    } else {
                        summary_invoice.InvoiceLines.push(JSON.parse(JSON.stringify(line)));
                    }
                }

                if(moment(invoice.period_end, 'YYYY-MM-DD').isAfter(moment(summary_invoice.period_end, 'YYYY-MM-DD'))){
                    summary_invoice.period_end = invoice.period_end;
                }
                if(moment(invoice.period_start, 'YYYY-MM-DD').isBefore(moment(summary_invoice.period_start, 'YYYY-MM-DD'))){
                    summary_invoice.period_start = invoice.period_start;
                }

                summary_invoice.sub_total += invoice.sub_total;
                summary_invoice.total_tax += invoice.total_tax;
                summary_invoice.discounts += invoice.discounts;
                summary_invoice.total_due += invoice.total_due;
                summary_invoice.total_payments += invoice.total_payments;
                summary_invoice.balance += invoice.balance;
            }

            }

            return summary_invoice;
        },
        daysOfMonth(){
            var days = [];
            var v;
            var s= ["th","st","nd","rd"];
            for(var i = 1; i <= 31; i++){
                v=i%100;
                var label = i + (s[(v-20)%10]||s[v]||s[0]);

                days.push({
                    id: i,
                    name: label
                });
            }
            return days;
        },
        computedContactPhoneVerified() {
          if(this.selectedTenant.Phones && this.selectedTenant.Phones[0] && this.selectedTenant.Phones[0].phone_verified && this.selectedTenant.Phones[0].phone_verified == 1){
              return true;
          } else {
              return false;
          }
        },
        computedContactTitle() {
            console.log(this.selectedTenant, 'ten')
            if(this.selectedTenant){
                return (this.selectedTenant.first ? this.selectedTenant.first : '') + (this.selectedTenant.middle ? ' ' + this.selectedTenant.middle : '') + (this.selectedTenant.last ? ' ' + this.selectedTenant.last : '');
            } else {
                return undefined;
            }
        },
        computedContactPhone() {
            if(this.selectedTenant && this.selectedTenant.Phones && this.selectedTenant.Phones[0] && this.selectedTenant.Phones[0].phone){
                return this.selectedTenant.Phones[0].phone;
            } else {
                return undefined;
            }
        },
        computedContactAddress() {
            if(this.selectedTenant && this.selectedTenant.Addresses && this.selectedTenant.Addresses[0] && this.selectedTenant.Addresses[0].Address){
                return (this.selectedTenant.Addresses[0].Address.address ? this.selectedTenant.Addresses[0].Address.address : '') + (this.selectedTenant.Addresses[0].Address.address2 ? ' ' + this.selectedTenant.Addresses[0].Address.address2 + ', ' : '') + this.$options.filters.formatAddress(this.selectedTenant.Addresses[0].Address); 
            } else {
                return undefined;
            }
        },
        computedAccessCode(){
            if(this.isSpaceAccessCodesVendor && this.leaseData && this.leaseData.unit_id && this.spaceAccess && this.spaceAccess[0] && this.spaceAccess[0].pin && this.spaceAccess.find(i => i.unit_id === this.leaseData.unit_id).pin){
                return this.spaceAccess.find(i => i.unit_id === this.leaseData.unit_id).pin;
            } else if(!this.isSpaceAccessCodesVendor && this?.access?.length){
                let matchingUnits = this.access.filter((access) => access.unit_number === this.leaseData.Unit.number);
                return matchingUnits[0]?.pin;
            } else {
                return undefined;
            }
        }
    },
    filters: {
        formatDate(date){
            return moment(date).format('MMM DD, YYYY');
        }
    },
    async mounted() {
        this.promotionsArray = this.getPromotions();
        this.discountsArray = this.getDiscounts();
       // this.setPaymentCycle();
        
    },
    methods: {
        ...mapMutations({
            setContact: 'onBoardingStore/setContact',
            setPCycleOptions: 'onBoardingStore/setPCycleOptions',
            setTaxExemptStore: 'onBoardingStore/setTaxExempt',
            setTaxExemptDescriptionStore: 'onBoardingStore/setTaxExemptDescription',
        }),
        viewEntity(entity, entity_id) {
            let params = {};
            params = {
                component: entity,
                props: {},
            };
            params.props[entity + "_id"] = entity_id;
            this.$store.dispatch("navigationStore/openSlideOut", params);
        },
        showNotificationModal(key){
            this.notificationModalType = key;
            if (key === 'edit_bill_day' && !this.hasPermission('edit_bill_day_transfer_process')) {     
                this.notificationModal = true;
            }else if(key === 'no_tax_rent' && !this.hasPermission(key)){      
      	        this.notificationModal = true;
            }
        },
        removeFee(index) {
            const newFeesArray = this.getFeeProducts.filter((fee, key) => key !== index);
            if(newFeesArray.length) {
                this.transferData.productFees = newFeesArray;
            } else {
                this.transferData.productFees = [];
                this.transferData.productFees.push({product_id: ''});
            }
        },
        minimumNoOfMonthsToPay() {
            // Currently one promotion can be selected during Transfers
            const selectedPromotions = this.transferData?.promotion;
            const maxRequiredMonthsInPromotions = selectedPromotions?.required_months || 0;
            const minNoOfAdditionalMonths = maxRequiredMonthsInPromotions >= 1 ? maxRequiredMonthsInPromotions - 1 : 0;
            return minNoOfAdditionalMonths;
        },
        async promotionChanged() {
            this.form.promotions = [];                        

            if(this.transferData.promotion) {
                this.form.promotions.push({Promotion: {id: this.transferData.promotion.id}});
                const minNoOfAdditionalMonths = this.minimumNoOfMonthsToPay();
                this.$store.commit('onBoardingStore/setMinBilledMonths', minNoOfAdditionalMonths);
                this.$store.commit('onBoardingStore/setBilledMonths', minNoOfAdditionalMonths);
            }
            this.form.payment_cycle = this.transferData.payment_cycle || 'Monthly';
            this.setBillingCycle();
            this.$store.dispatch('onBoardingStore/setConfigurationLease', { ...this.form });
            await this.$store.dispatch('onBoardingStore/getEstimate', {config:true, is_transfer: 1, transfer_lease_id: this.leaseData.id});
            // to calculate/refresh the payment calculation based on the promotion change
            EventBus.$emit('updatePaymentDetails');
        },
 
        async setPaymentCycle(){
            // let form = JSON.parse(JSON.stringify(this.form));
            
            if(this.transferData.payment_cycle) {
                this.form.payment_cycle = this.transferData.payment_cycle;
            
                switch(this.form.payment_cycle){
                  case 'Monthly': 
                  default:
                    // form.discount_id = '';
                    // form.promotions = [];    
                    // let payment_cycle = this.payment_cycle_options.find(o => o.label.toLowerCase() == this.form.payment_cycle.toLowerCase());
                    
                    // this.$store.commit('onBoardingStore/setMinBilledMonths', payment_cycle.period);
                    // this.$store.commit('onBoardingStore/setBilledMonths', payment_cycle.period);
                }
                this.$store.dispatch('onBoardingStore/setConfigurationLease', { ...this.form });
                
            }

        }, 
        async productChanged() {
            this.form.products = [];
            if(this.transferData.productFees.length) {
                this.form.products = this.getFeeProducts.filter(fee => fee.product_id !== '');
            }
            this.form.discount_id = this.transferData.discount_id;
            this.form.payment_cycle = this.transferData.payment_cycle || 'Monthly';
            this.setBillingCycle()
            this.$store.dispatch('onBoardingStore/setConfigurationLease', { ...this.form });
            await this.$store.dispatch('onBoardingStore/getEstimate', {config:true, is_transfer: 1, transfer_lease_id: this.leaseData.id});
            // to calculate/refresh the payment calculation based on the fee change
            EventBus.$emit('updatePaymentDetails');
        },
        autopayChanged() {
            this.$emit('selectedPaymentMethod', this.transferData.autopay_id);
        },
        taxExemptDescriptionChanged(){
            this.setTaxExemptDescriptionStore(this.form.tax_exempt_description);
            this.$store.dispatch('onBoardingStore/setConfigurationLease', { ...this.form });
            this.transferData.tax_exempt_description = this.form.tax_exempt_description;
            
        },
        async taxExemptChanged() {
            this.setTaxExemptStore(this.form.tax_exempt);
            this.$store.dispatch('onBoardingStore/setConfigurationLease', { ...this.form });
            this.transferData.tax_exempt = this.form.tax_exempt || false;
            if(!this.form.tax_exempt) {
                this.form.tax_exempt_description = ''
            }
            await this.$store.dispatch('onBoardingStore/getEstimate', {config:true, is_transfer: 1, transfer_lease_id: this.leaseData.id, tax_exempt: this.form.tax_exempt || false, tax_exempt_description: this.form.tax_exempt_description});
            EventBus.$emit('updatePaymentDetails');
     
        },
        async insuranceChanged() {
            if(this.form.insurance_id != '') {
                this.insuranceCoverageChanged(false);
            } else {
                this.insuranceCoverageChanged();
            }
            const isTableRateInsurance = this.isTableRateInsurance();
            if(isTableRateInsurance && !this.form.table_rate_coverage_amount) return;
            if(isTableRateInsurance) this.updateTableRateCoverageAmount();
            this.form.insurance_id = this.transferData.insurance_id;
            this.form.payment_cycle = this.transferData.payment_cycle || 'Monthly';
            this.setBillingCycle();
            this.$store.dispatch('onBoardingStore/setConfigurationLease', { ...this.form });
            await this.$store.dispatch('onBoardingStore/getEstimate', {config:true, is_transfer: 1, transfer_lease_id: this.leaseData.id});
            // to calculate/refresh the payment calculation based on the insurance change
            EventBus.$emit('updatePaymentDetails');
        },
        isTableRateInsurance() {
            return this.insurances.length && this.insurances[0]?.insurance_type === "table_rate";
        },
        updateTableRateCoverageAmount() {
            const tiers = this.insurances[0].TableRateTiers;
            const lastTier = tiers[tiers.length - 1];
            if(lastTier) this.form.table_rate_coverage_amount = Math.min(this.form.table_rate_coverage_amount, lastTier.coverage_limit);
        },
        async freeInsuranceSelected() {
            this.$store.dispatch('onBoardingStore/setConfigurationLease', { ...this.form });
            await this.$store.dispatch('onBoardingStore/getEstimate', {config:true, is_transfer: 1, transfer_lease_id: this.leaseData.id});
            EventBus.$emit('updatePaymentDetails');
        },
        insuranceCoverageChanged(flag = true) {
            let userInsuranceData = {};
            userInsuranceData.decline_insurance = false;
            userInsuranceData.insurance_exp_month = '';
            userInsuranceData.insurance_exp_year = '';
            if(flag) {
                userInsuranceData.decline_insurance = true;
                userInsuranceData.insurance_exp_month = this.insurance_exp_month;
                userInsuranceData.insurance_exp_year = this.insurance_exp_year;
            }
            this.$emit('selectedUserHasOwnInsurance', userInsuranceData);
        },
        getPromotions() {
            this.promotionsArray = [{ id: "", name: "No Promo" }];
            if (
                this.configuration_unit.id &&
                this.promotions?.length
            ) {
                let promoData = this.promotions.filter(p => p.label === 'promotion');
                promoData.unshift({ id: "", name: "No Promo" });
                if(this.lease && this.lease.promotions && this.lease.promotions.length){
                    this.transferData.promotion.id = this.lease.promotions[0].promotion_id;
                    this.transferData.promotion.name = this.lease.promotions[0].Promotion.name;
                }
                return promoData;
            } else {
                return [{ id: "", name: "No Promo" }];
            }
        },
        getDiscounts() {
            this.discountsArray = [{ id: "", name: "No Discount" }];
            if (
                this.configuration_unit.id &&
                this.discounts?.length
            ) {
                let discountData = this.discounts;
                discountData.unshift({ id: "", name: "No Discount" });
                if(this.lease && this.lease.discount_id){
                    this.transferData.discount_id = this.lease.discount_id;
                }
                return discountData;
            } else {
                return [{ id: "", name: "No Discount" }];
            }
        },
        async billingOnChanged() {
            this.form.bill_day = this.transferData.bill_day;
            this.form.payment_cycle = this.transferData.payment_cycle || 'Monthly';
            this.setBillingCycle();
            this.$store.dispatch('onBoardingStore/setConfigurationLease', { ...this.form });
            await this.$store.dispatch('onBoardingStore/getEstimate', {config:true, is_transfer: 1, transfer_lease_id: this.leaseData.id });
            // to calculate/refresh the payment calculation based on the billing on change
            EventBus.$emit('updatePaymentDetails');
        },
        async setConfigurationInfo(unitID) {
            this.$store.commit("onBoardingStore/resetConfigurationLease");
            this.$store.commit("onBoardingStore/resetConfigurationInvoice");
            await this.$store.dispatch(
                "onBoardingStore/getConfigurationUnitInfo",
                {
                    unit_id: unitID,
                }
            );
        },
        setContactDetail(contact){
          this.selectedTenant = contact;
          this.setContact(contact);
        },
        setBillingCycle(){
            this.form.billing_cycle_id = this.transferData?.billing_cycle_id;
            this.form.BillingCycle = this.leaseData?.BillingCycleOptions?.find(bco => bco.billing_cycle_id === this.transferData.billing_cycle_id);
            this.form.BillingCycleOptions = this.leaseData?.BillingCycleOptions;
            if(this.transferData?.billing_cycle_id) {
                this.transferData.promotion = [];
                this.form.promotions = [];
                const minNoOfAdditionalMonths = this.minimumNoOfMonthsToPay();
                this.$store.commit('onBoardingStore/setMinBilledMonths', minNoOfAdditionalMonths);
                this.$store.commit('onBoardingStore/setBilledMonths', minNoOfAdditionalMonths);
            }
        },
        updateCoverageValue(coverageValue){
          this.form.table_rate_coverage_amount = coverageValue;
          if(this.form.table_rate_coverage_amount) this.insuranceChanged();
        }
    },
    destroyed() {
        EventBus.$off('tenant_updated');
        this.setTaxExemptStore(false);
        this.setTaxExemptDescriptionStore(null);
    },
    async created() {
        this.selectedTenant = this.contacts?.id ? this.contacts : this.$props.contact;
        EventBus.$on('tenant_updated', this.setContactDetail);
        // Getting the payment cycle options from the on boarding store
        this.payment_cycle_options = this.pCycleOptions; 
        this.form.PaymentCycleOptions = this.pCycleOptions; 
        this.form.payment_cycle = this.lease.payment_cycle;
        this.setBillingCycle();
        this.form.tax_exempt = this.leaseData.tax_exempt || false;
        this.form.tax_exempt_description = this.leaseData.tax_exempt_description;
        this.form.table_rate_coverage_amount = this.leaseData.table_rate_coverage_amount;
        this.setTaxExemptStore(this.leaseData.tax_exempt || false);
        this.setTaxExemptDescriptionStore(this.leaseData.tax_exempt_description);
    },
    watch: {
        async configuration_unit() {
            if (this.configuration_unit.id) {
                this.transferCalculation = false;
                await this.$store.dispatch('onBoardingStore/buildDefaultLease',  this.configuration_unit);
                await this.$store.dispatch('revManStore/fetchActivePromotions', { contact_id: this.selectedTenant?.id || null, property_id: this.configuration_unit?.property_id || null, unit_id: this.configuration_unit?.id || null, channel_type: "promo-plan", rental_flow: true });
                await this.$store.dispatch('revManStore/fetchPropertyDiscounts', { property_id: this.configuration_unit?.property_id || null });

                this.promotionsArray = await this.getPromotions();
                this.discountsArray = await this.getDiscounts();

                if(this.unit_template && this.unit_template.payment_cycle_options && this.unit_template.payment_cycle_options.length){
                    this.payment_cycle_options = [].concat([{
                    label: 'Monthly'
                    }], this.unit_template.payment_cycle_options); 
                    this.form.payment_cycle = this.form.payment_cycle || 'Monthly';
                }
                // Setting the payment cycle options into the on boarding store
                this.setPCycleOptions(this.payment_cycle_options);
                
                this.transferData.promotion = {id: "", name: "No Promo"};
                this.form.discount_id = '';
                this.form.security_deposit = 0;
                let date = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
                this.form.start_date = date;
                this.form.insurance_id = this.transferData.insurance_id;
                this.transferData.bill_day = JSON.parse(JSON.stringify(this.configuration_lease.bill_day));
                this.form.payment_cycle = this.transferData.payment_cycle || 'Monthly';
                this.transferData.payment_cycle = this.transferData.payment_cycle || 'Monthly';
                this.setBillingCycle()
                this.setPaymentCycle();
                this.form.bill_day = this.transferData.bill_day;
                this.form.PaymentCycleOptions = this.payment_cycle_options;
                if(this.isTableRateInsurance()) this.updateTableRateCoverageAmount();
                let form = JSON.parse(JSON.stringify(this.form));
                await this.$store.dispatch('onBoardingStore/setConfigurationLease', { ...form });
                await this.$store.dispatch('onBoardingStore/getEstimate', { config:true, unit: this.configuration_unit, is_transfer: 1, transfer_lease_id: this.leaseData.id });
                this.transferCalculation = true;
            }
        },
        templateFees: {
            handler: function (feesValues) {
                this.transferData.productFees = [];
                if(feesValues && feesValues.length) {
                    feesValues.map(fee => {
                        // Note: This check is here because all properties fees are returned from BE,
                        // We can remove it once its resolved there
                        if(this.fees.find(f => f.id && f.id == fee.product_id)) {
                            this.transferData.productFees.push({ product_id: fee.product_id });
                        }
                    });
                } 
                
                if(this.transferData.productFees.length === 0) {
                   this.transferData.productFees.push({ product_id: '' });
                }

                this.productChanged();
            },
            deep: true
        },
        selectedHours(val) {
            this.$emit("update:selectedHoursObject", val);
        },
        "form.table_rate_coverage_amount":{
            handler(val){
                this.$emit("table_rate_coverage_amount_updated", val);
            },
        }
    },
};
</script>

<style scoped>

.v-expansion-panel.transfer-custom-expansion-panel-elevation::before {
    box-shadow: 0px 4px 10px 3px rgba(71, 84, 116, 0.1) !important;
}
</style>