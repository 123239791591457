<template>
  <div class="tab-section">
    <hb-header fewer-actions :padding="false" align-top class="mt-n2">
      <template v-slot:left>
          <hb-tabs v-if="tabOptions.length" class="mt-n2">
              <v-tab
                v-for="item in tabOptions"
                :key="item.key"
                :href="'#tab-' + item.key"
                @click="$emit('tabChange', item.key)"
                :ripple="false"
              >
                {{ item.label }}
                <hb-tooltip v-if="component=='settings-insurance'">
                  <template v-slot:body>
                    {{ item.description }}
                  </template>
                </hb-tooltip>
              </v-tab>
            </hb-tabs>
      </template>
    </hb-header>
  </div>
</template>

<script>
export default {
  name: "TabSection",
  props: {
    component: {
      type: String,
      default: "",
    },
    tabOptions: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
<style scoped>
.tab-section {
  margin-bottom: 8px !important;
}
</style>
