<template>
  <div class="lead-interaction-options">
    <div>
      <div class="optionLabel hb-text-light hb-font-body">
        How was the lead initiated?
      </div>
      <hb-select 
        class="selection-box" 
        v-model="selectedInteractionOption" 
        :items="interactionDropdownOptions" 
        :placeholder="'Select Option'" 
        :clearable="false"
        @input="handleSelection('interactionSelected')"
        v-validate="'required'" 
        data-vv-scope="lead_interaction"
        data-vv-as="Lead Initiated"
        id="lead_initiated"
        name="lead_initiated"
        :error="errors.collect('lead_interaction.lead_initiated')?.length > 0" >
      </hb-select>
    </div>
    <div>
      <div class="optionLabel hb-text-light hb-font-body">
        Where did the lead come from?
      </div>
      <hb-select 
        class="selection-box" 
        v-model="selectedSourceOption" 
        :items="sourceDropdownOptions" 
        :placeholder="'Select Source'" 
        :clearable="false"
        @input="handleSelection('interactionSelected')"
        v-validate="'required'" 
        data-vv-scope="lead_interaction"
        data-vv-as="Lead Source"
        id="lead_source"
        name="lead_source"
        :error="errors.collect('lead_interaction.lead_source')?.length > 0" >
      </hb-select>
      <template v-if="selectedSourceOption && hasChildren(selectedSourceOption)">
        <div class="optionLabel hb-text-light hb-font-body">
          Source Type
        </div>
        <hb-select 
        class="selection-box" 
          v-model="selectedChildOption" 
          :items="getChildOptions(selectedSourceOption)"
          :placeholder="'Select Source Type'" 
          @input="handleChildSelection('interactionSelected')" 
          :clearable="false"
          v-validate="'required'" 
          data-vv-scope="lead_interaction"
          data-vv-as="Lead Source Type"
          id="lead_source_type"
          name="lead_source_type"
          :error="errors.collect('lead_interaction.lead_source_type')?.length > 0" >
        </hb-select>
      </template>
    </div>
  </div>
</template>

<script>
import api from "../../../assets/api.js";
import { mapGetters, mapActions } from 'vuex';
export default {
  name: "LeadInteractionOptions",

  data() {
    return {
      selectedSourceOption: "",
      selectedInteractionOption: "",
      selectedChildOption: "",
      interactionDropdownOptions: [],
      sourceDropdownOptions: [],
      interactionDropdownData: [],
      sourceDropdownData: [],
      interactionDropdownResponse: [],
    };
  },
  props:['showCharmOption'],
  computed: {
     ...mapGetters({
      getleadInteractionData: 'onBoardingStore/lead_interaction_data'
    }),
  },
  async created() {
    if (this.getleadInteractionData) {
      this.selectedInteractionOption = this.getleadInteractionData.leadInitiated || "";
      this.selectedSourceOption = this.getleadInteractionData.leadSouce || "";
      this.selectedChildOption = this.getleadInteractionData.leadSourceChild || "";
    }
    await this.getInteractionOptions();
  },
  mounted() {},
  methods: {
    ...mapActions({
         addLeadInteractionData : 'onBoardingStore/addLeadInteractionData'
    }),
    async getReq(path) {
      return api.get(this, path);
    },
    async getInteractionOptions() {
      try {
        const interactionOptions = await this.getReq(
          api.LEADS + "interaction-options"
        );
        this.interactionDropdownResponse = interactionOptions
        /* this.interactionDropdownOptions =
          interactionOptions?.interactionDropdownOptions.map(
            (item) => item.name
          ); */
          this.handleInteractionOptions()
        this.sourceDropdownOptions =
          interactionOptions?.sourceDropdownOptions.map((item) => item.name);
        this.interactionDropdownData =
          interactionOptions?.interactionDropdownOptions;
        this.sourceDropdownData = interactionOptions?.sourceDropdownOptions;
      } catch (error) {
        console.log(
          "error =========================== getInteractionOptions :",
          error
        );
      }
    },
    hasChildren(option) {
      const foundOption = this.sourceDropdownData.find(
        (item) => item.name === option
      );
      return foundOption && foundOption.hasChild === 1;
    },
    getChildOptions(parentOption) {
      const foundOption = this.sourceDropdownData.find( (item) => item.name === parentOption );
      return foundOption ? foundOption.children.map((item) => item.name) : [];
    },
    handleSelection(type) {
      this.$emit(type, {
        selectedInteractionOption: this.selectedInteractionOption,
        selectedSourceOption: this.selectedSourceOption,
        selectedChildOption: this.selectedChildOption,
      });
      if (!this.hasChildren(this.selectedSourceOption)) {
        this.selectedChildOption = null
        // this.$emit("select", this.selectedOption);
      }
      let data = {
        leadInitiated : this.selectedInteractionOption,
        leadSouce : this.selectedSourceOption
      }
      this.addLeadInteractionData(data);
    },
    handleChildSelection(type) {
      this.$emit(type, {
        selectedInteractionOption: this.selectedInteractionOption,
        selectedSourceOption: this.selectedSourceOption,
        selectedChildOption: this.selectedChildOption,
      });
      let data = {
        leadInitiated : this.selectedInteractionOption,
        leadSouce : this.selectedSourceOption,
        leadSourceChild : this.selectedChildOption
      }
      this.addLeadInteractionData(data);
    },
    handleInteractionOptions() {
      this.interactionDropdownOptions = this.interactionDropdownResponse?.interactionDropdownOptions.map((item) => item.name);
      if (this.showCharmOption) {
        let phoneIndex = this.interactionDropdownOptions.findIndex(option => option === "Phone");
        
        if (phoneIndex !== -1) {
          this.interactionDropdownOptions.splice(phoneIndex, 1);
        }
        
        // this.interactionDropdownOptions.splice(1, 1);
      } else {
        let charmIndex = this.interactionDropdownOptions.findIndex(option => option === "Charm Phone Call");
        if (charmIndex !== -1) {
          this.interactionDropdownOptions.splice(charmIndex, 1);
        }
        // this.interactionDropdownOptions.splice(2, 1);
      }
    }
  },
  watch:{
    showCharmOption(){
      this.handleInteractionOptions()
    }
  }
};
</script>

<style scoped>
.lead-interaction-options >>> .v-text-field__details {
  display: none!important;
}

.optionLabel{
  margin-bottom: 16px
}
.selection-box{
  margin-bottom: 16px;
}
</style>
