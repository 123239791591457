import { render, staticRenderFns } from "./Checklist.vue?vue&type=template&id=08377884&scoped=true&"
import script from "./Checklist.vue?vue&type=script&lang=js&"
export * from "./Checklist.vue?vue&type=script&lang=js&"
import style0 from "./Checklist.vue?vue&type=style&index=0&id=08377884&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08377884",
  null
  
)

export default component.exports