<template>
  <div class="new-tenant-walkthrough">

      <div class="new-tenant-walkthrough-container">
        <hb-blade-header
          title="Lead Follow Up"
          title-icon="mdi-account-plus"
          back-button-off
          @close="$emit('close')"
        >

          <template v-slot:left v-if="$vuetify.breakpoint.smAndUp">
            <v-autocomplete
              v-show="tenantList.length"
              rounded
              hide-details
              v-model="active_reservation"
              :items="tenantList"
              item-value="temp_id"
              item-text="lead_first"
              outlined
              dense
              single-line
              return-object
              background-color="white"
              label="Select Lead"
              @change="onTenantChange"
              @focus="onFocus"
              ref="autoCompleteId"
            >
              <template v-slot:no-data>
                <v-list-item v-show="tenantList && tenantList.length">
                  <v-list-item-title>No Lead found</v-list-item-title>
                </v-list-item>
              </template>

              <template v-slot:selection="data">
                <v-list-item class="px-0" v-if="!autoCompleteFocus">
                  <v-list-item-content class="py-0">
                    <v-list-item-title>
                      <strong style="font-style: normal;font-size: 14px;" class="hb-text-night">{{data.item.lead_first}} {{data.item.lead_last}}</strong>
                      <span style="font-style: normal;font-weight: 500;font-size: 14px;"> - {{ has_reservation ? 'Reservation': 'Lead'}} </span>
                      <span class="float-right" :style="time_color" style="font-size:12px;">{{duration}}</span>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>

              <template v-slot:item="data" >
                <v-row>
                  <v-col cols="5" class="pr-0 py-0">
                    <strong>{{data.item.lead_first}} {{data.item.lead_last}}</strong><br />
                  </v-col>
                  <v-col cols="4" class="pa-0">
                    {{ hasReservation(data.item) ? 'Reservation': 'Lead'}}
                  </v-col>
                  <v-col cols="3" class="pl-0 py-0 text-right">
                    <span :style="checkLeadCriteria(calculateTimer(hasReservation(data.item) ? data.item.reservation_time: data.item.lead_time))">{{calculateTimer(hasReservation(data.item) ? data.item.reservation_time: data.item.lead_time)}}</span>
                  </v-col>
                </v-row>
              </template>
            </v-autocomplete>
          </template>
          <template v-slot:right>
            <hb-btn
              v-if="active_reservation"
              class="mr-1"
              icon
              tooltip="Lead Info"
              @click="summary_panel = summary_panel === 'overview' ? null : 'overview'"
              :active="summary_panel === 'overview'"
              hover-background-color="#CAEAEA"
            >
              mdi-account-box
            </hb-btn>
            <hb-btn
              v-if="active_reservation"
              class="mr-1"
              icon
              tooltip="Communication"
              @click="summary_panel = summary_panel === 'chat' ? null : 'chat'"
              :active="summary_panel === 'chat'"
              hover-background-color="#CAEAEA"
            >
              mdi-forum
            </hb-btn>
          </template>
        </hb-blade-header>

        <div class="new-tenant-walkthrough-content-section">
          <div class="onboarding-container">
              <div class="walkthrough-body">
                
                  <div class="px-6" :class="{'py-6' : $vuetify.breakpoint.smAndUp}">
                    <!-- <status @resetStatus="errorClear($options.name)" v-if="errorHas($options.name)" :message="errorGet($options.name)" status="error"></status> -->
                    <!-- <v-row class="call-info-header mx-0 mb-4" v-if="active_reservation">
                      <v-col cols='12' class="pb-1">
                        <div>
                          <v-icon size="22">mdi-phone</v-icon>
                          <span class="font-weight-medium ml-2">Collection Call:</span>
                          <span class="font-weight-regular ml-2">
                            {{active_reservation.Contact.first}} {{active_reservation.Contact.middle}} {{active_reservation.Contact.last}}
                            has completed his online registration.</span>
                          <v-btn icon class="float-right mt-n2" color="hb-text-night" @click="panel = null" >
                            <v-icon>mdi-close</v-icon>
                          </v-btn>
                        </div>

                      </v-col>
                    </v-row> -->

                    <!-- <status @resetStatus="errorClear($options.name)" v-if="errorHas($options.name)" :message="errorGet($options.name)" status="error"></status> -->
                    <v-autocomplete
                      class="my-3"
                      v-if="tenantList.length  && $vuetify.breakpoint.xs"
                      rounded
                      hide-details
                      v-model="active_reservation"
                      :items="tenantList"
                      item-value="temp_id"
                      item-text="lead_first"
                      outlined
                      dense
                      single-line
                      return-object
                      background-color="white"
                      label="Select Lead"
                      @change="onTenantChange"
                      @focus="onFocus"
                      ref="autoCompleteId"
                    >
                      <template v-slot:no-data>
                        <v-list-item v-show="tenantList && tenantList.length">
                          <v-list-item-title>No Lead found</v-list-item-title>
                        </v-list-item>
                      </template>

                      <template v-slot:selection="data">
                        <v-list-item class="px-0" v-if="!autoCompleteFocus">
                          <v-list-item-content class="py-0">
                            <v-list-item-title>
                              <strong style="font-style: normal;font-size: 14px;" class="hb-text-night">{{data.item.lead_first}} {{data.item.lead_last}}</strong>
                              <span style="font-style: normal;font-weight: 500;font-size: 14px;"> - {{ has_reservation ? 'Reservation': 'Lead'}} </span>
                              <span class="float-right" :style="time_color" style="font-size:12px;">{{duration}}</span>
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>

                      <template v-slot:item="data" >
                        <v-row>
                          <v-col cols="5" class="pr-0 py-0">
                            <strong>{{data.item.lead_first}} {{data.item.lead_last}}</strong><br />
                          </v-col>
                          <v-col cols="4" class="pa-0">
                            {{ hasReservation(data.item) ? 'Reservation': 'Lead'}}
                          </v-col>
                          <v-col cols="3" class="pl-0 py-0 text-right">
                            <span :style="checkLeadCriteria(calculateTimer(hasReservation(data.item) ? data.item.reservation_time: data.item.lead_time))">{{calculateTimer(hasReservation(data.item) ? data.item.reservation_time: data.item.lead_time)}}</span>
                          </v-col>
                        </v-row>
                      </template>
                    </v-autocomplete>

                    <!-- <status @resetStatus="successClear($options.name)" v-if="successHas($options.name)" :message="successGet($options.name)" status="success"></status> -->

                    <v-expansion-panels v-model="panel" :disabled="!active_reservation">
                      <hb-expansion-panel key="0" content-padding :top-margin="false">
                        <template v-slot:title>
                          Log Phone Call <span class="ml-4" v-if="activeLeasePhone">Mobile {{ activeLeasePhone | formatPhone}}</span>
                        </template>

                        <template v-slot:content>
                          <v-row class="call-info mx-0" v-if="webLeadResEvent">
                            <v-col cols="12">
                              <span class="font-weight-medium title mb-2">This timer started with the reservation</span>
                             <span  class="float-right timer mr-1" :style="time_color" v-if="active_reservation" >{{ duration }}</span>
                              <br>
                              <ol class="text-body-2 font-weight-medium pl-4">
                                <li class="py-1">
                                  <span>The faster you follow up the more rentals you get!</span>
                                </li>
                                <li class="py-1">
                                  <span>Every Reservation needs our service</span>
                                </li>
                                <li class="py-1">
                                  <span>Smile and be friendly</span>
                                </li>
                              </ol>
                            </v-col>
                          </v-row>
                          <v-row no-gutters :class="{ 'mt-6' : webLeadResEvent }">
                            <v-col cols="12" md="6" class="pt-0 pb-0" :class="{ 'pr-3' : $vuetify.breakpoint.mdAndUp }">
                              <hb-select
                                :items="answerList"
                                v-model="tenantAnswer"
                                placeholder="Did the Tenant Answer?"
                                name="answer"
                                v-validate="'required'"
                                data-vv-as="Call Answer"
                                :error="errors.has('answer')"
                                full
                              ></hb-select>
                            </v-col>
                            <v-col cols="12" md="6" class="pt-0 pb-0" :class="{ 'mt-6' : $vuetify.breakpoint.smAndDown }">
                              <!-- <v-text-field
                                v-model="noteContent"
                                placeholder="Enter note"
                                name="note_content"
                                v-validate="'required'"
                                data-vv-as="note"
                                :error="errors.has('note_content')"
                                :error-messages="errors.collect('note_content')"
                                ></v-text-field> -->
                              <hb-textarea
                                v-model="noteContent"
                                name="note_content"
                                v-validate="'required'"
                                data-vv-as="Note"
                                :error="errors.has('note_content')"
                                placeholder="Enter Notes"
                              ></hb-textarea>
                            </v-col>
                          </v-row>
                        </template>
                        <template v-slot:footer>
                          <hb-bottom-action-bar cancel-off>
                              <template v-slot:right-actions>
                                  <hb-btn color="secondary" @click="setReminder">Create Task</hb-btn>
                                  <hb-btn @click="sendNote" color="primary"> Save Call Record </hb-btn>
                              </template>
                          </hb-bottom-action-bar>
                        </template>
                      </hb-expansion-panel>
                    </v-expansion-panels>
                    <v-row class="ma-0" v-if="has_reservation">
                      <h2 class="title mt-4 mb-2">Reservation Summary {{active_reservation.reservation_start}}</h2>
                    </v-row>
                    <v-row v-if="dataLoading && has_reservation">
                      <v-col cols="6">
                            <!-- todo: active_reservation.Lease.Unit needs to return "unit.type", then replace 'type="unit"' with ':type="unit.type"' -->
                        <hb-info-card
                          v-if="active_reservation.Lease.Unit"
                          type="unit"
                          :title="active_reservation.Lease.Unit.number ? active_reservation.Lease.Unit.number : undefined"
                          :status="active_reservation.Lease.Unit.state ? active_reservation.Lease.Unit.state : undefined"
                          :property-info="computedPropertyInfo"
                          class="mb-4"
                          @click="viewEntity('unit', active_reservation.Lease.Unit.id)"
                        >
                          <template v-slot:button>
                            <hb-btn small color="secondary" :disabled="!active_reservation" @click="newLead('change')">Change Space</hb-btn>
                          </template>
                        </hb-info-card>
                        <hb-info-card
                          v-if="active_reservation.Contact"
                          type="contact"
                          :title="computedContactTitle"
                          :status="active_reservation.Contact.status ? active_reservation.Contact.status : undefined"
                          :phone="computedContactPhone | formatPhone"
                          :email="active_reservation.Contact.email ? active_reservation.Contact.email : undefined"
                          :contact-address="computedContactAddress"
                          @click="viewEntity('contact', active_reservation.Contact.id)"
                        >
                          <template v-slot:button>
                            <hb-btn small color="secondary" :disabled="!active_reservation" @click="newLead('lead')">Edit information</hb-btn>
                          </template>
                        </hb-info-card>
                      </v-col>
                      <v-col cols="6" class="pa-0 hb-text-night">
                        <div v-if="dataLoading" class="ls-bottom-content px-4 py-3">
                          <v-row class="mx-0 mb-1">
                              <span class="caption">Move-In Date</span><br>
                          </v-row>
                          <v-row class="mx-0 mb-1">
                              <span class="my-1" style="color:#00848E">
                                 <v-icon color="#00848E" size="20" left >mdi-calendar</v-icon>
                                {{reservation_start | formatLocalShortDate}}
                              </span>
                          </v-row>
                          <v-divider class="mb-1"></v-divider>
                          <v-row class="mx-0" v-for="charge in active_reservation.Setup.Charges.Detail">
                            <v-col md="7" class="px-1 py-1">
                                {{charge.name}}
                            </v-col>
                            <v-col md="5" class="px-1 py-1 text-right">{{charge.cost | formatMoney}}</v-col>
                          </v-row>
  
                          <v-row class="mx-0" v-for="promos in active_reservation.Setup.Discounts">
                            <v-col md="7" class="px-1 py-0">
                              {{promos.Promotion.name}}
                            </v-col>
                            <v-col md="5" class="px-1 py-0 pt-0 text-right" v-if="promos.Promotion.type === 'percent'">{{promos.Promotion.value}}% off</v-col>
                            <v-col md="5" class="px-1 py-0 pt-0 text-right" v-else-if="promos.Promotion.type === 'dollar'">${{promos.Promotion.value}} off</v-col>
                            <v-col md="5" class="px-1 py-0 pt-0 text-right" v-else-if="promos.Promotion.type === 'fixed'">${{promos.Promotion.value}} move in</v-col>
                          </v-row>
                          <v-divider class="mt-2 mb-2"></v-divider>

                          <v-row class="mx-0">
                            <v-col md="7" class="px-1 py-1">Subtotal</v-col>
                            <v-col md="5" class="px-1 py-1 text-right"> {{active_reservation.Setup.Charges.sub_total | formatMoney }}</v-col>
                          </v-row>

                          <v-row class="mx-0">
                            <v-col md="7" class="px-1 py-1">Discounts</v-col>
                            <v-col md="5" class="px-1 py-1 text-right">( {{active_reservation.Setup.Charges.discounts | formatMoney }} ) </v-col>
                          </v-row>

                          <v-row class="mx-0">
                            <v-col md="7" class="px-1 py-1">Taxes</v-col>
                            <v-col md="5" class="px-1 py-1 text-right">{{active_reservation.Setup.Charges.total_tax | formatMoney }}</v-col>
                          </v-row>

                          <v-divider class="mt-2 mb-2"></v-divider>

                          <v-row class="mx-0">
                            <v-col md="7" class="px-1 py-1"><strong>Move-In Cost</strong></v-col>
                            <v-col md="5" class="px-1 py-1 text-right"><strong>{{active_reservation.Setup.Charges.balance | formatMoney }}</strong></v-col>
                          </v-row>

                        </div>
                      </v-col>
                    </v-row>
                  </div>
                
              </div>
          </div>
        
          <div class="new-tenant-info-panel align-stretch" v-if="summary_panel">
            <chat-window dense  chatWindowHeight="x-large" v-if="summary_panel === 'chat' && dataLoading" @hide="summary_panel = ''" :InteractionObj="active_reservation.Contact" from="lead_follow_up"></chat-window>
            <contacts-overview
              hideActionButtons
              readonly
              :openPanel="active_reservation.id"
              v-if="summary_panel === 'overview' && dataLoading && active_reservation"
              :contact="active_reservation && active_reservation.Contact"
              
              @close="summary_panel = ''"
              ref="leadOverviewRef"
              ></contacts-overview>
              
          </div>



        </div>
        
      </div>

      <div class="new-tenant-walkthrough-footer">
        <hb-bottom-action-bar
          @close="$emit('close')"
          :top-border="false"
        >
          <template v-slot:left-actions>
            <help-juice />
            <hb-btn
              icon
              tooltip="Create Task"
              @click="createTask"
              :active="reminderFlag"
            >
              mdi-calendar-today
            </hb-btn>
          </template>
          <template v-slot:right-actions>
              <hb-btn v-if="hasPermission('retire_lead')" color="secondary" :disabled="!active_reservation" @click="show_retire_lead = true">{{ lead.is_reservation_lead == 0 ? retireCancelLeadReservation[0] : retireCancelLeadReservation[1] }}</hb-btn>
              <hb-btn v-if="tenantList.length" color="secondary" :disabled="!active_reservation" @click="nextLead">Next Lead</hb-btn>
              <hb-btn color="primary" :disabled="!active_reservation" @click="newLead('move_in')">Move In</hb-btn>
          </template>
        </hb-bottom-action-bar>
      </div>

      <todo
        v-model="reminderFlag"
        v-if="reminderFlag"
        :title_text="title_text"
        :btn_text="btn_text"
        :defaultAction="'Lead Follow up'"
        :disableAction="true"
        :contact_id=" isReminder ? contact_id : ''"
        :lease_id=" isReminder ? lease_id : ''"
        :event_type=" isReminder ? lease_id ? 'reservation_follow_up' : 'lead_follow_up' : ''"

      >
      </todo>

      <send-information
        v-model="show_rental"
        v-if="active_reservation && dataLoading && show_rental"
        :mobileNumber="sms_enabled_phone"
        :email="active_reservation.Contact.email"
        @sendText="sendRentalText"
        @sendEmail="sendRentalEmail"
      >
      </send-information>
  
    <retire-lead
      v-model="show_retire_lead"
      v-if="active_reservation && show_retire_lead"
      @retired="$emit('close')"
      :contact="active_reservation.Contact"
      :lead_id="active_reservation.id"
      :is_retired_reservation="lead?.is_reservation_lead"
    >
    </retire-lead>
  </div>

</template>

<script>
import ChatWindow from '../../communication_center/ChatWindow.vue'
import ContactsOverview from '../../contacts/ContactsOverview.vue'
import Todo  from '../Todo.vue'
import RetireLead from '../RetireLead.vue';
import SendInformation from './SendInformationWidget.vue';
import Status from '../../includes/Messages.vue'

import api from '../../../assets/api.js'
import { EventBus } from '../../../EventBus.js'
import moment from 'moment'
import { mapGetters,mapActions } from 'vuex';
import { notificationMixin } from  '../../../mixins/notificationMixin.js';

export default {
  name: 'LeadFollowUp',
  mixins: [ notificationMixin ],
  data() {
    return {
      summary_panel: '',
      panel: null,
      answerList: [
        { text: 'Call answered', value: 'Call answered' },
        { text: 'Call answered but tenant not available', value: 'Call answered but tenant not available' },
        { text: 'No answer left message', value: 'No answer left message' },
        { text: 'No answer left message on voicemail', value: 'No answer left message on voicemail' },
        { text: 'No answer voicemail full', value: 'No answer voicemail full' },
        { text: 'No answer', value: 'No answer' },
        { text: 'Number no longer in service', value: 'Number no longer in service' },
        { text: 'Number is temporarily disconnected', value: 'Number is temporarily disconnected' },
        { text: 'Number belongs to someone else', value: 'Number belongs to someone else' }
      ],
      lease_id: '',
      contact_id: '',
      
      noteContent: '',
      tenantAnswer: null,
      reminderFlag: false,
      tenantList: [],
      active_reservation: null,
      dataLoading: false,
      show_retire_lead: false,
      show_rental:false,
      interval_id: null,
      duration: '',
      autoCompleteFocus: false,
      title_text: '',
      btn_text: '',
      leadCriteria: {
        acceptable: '#02AD0F',
        overDue: '#ffa300',
        expired: '#FB4C4C',
        none: '#101318'
      },
      time_color: {},
      isReminder: false,
      is_lease_setup: false,
      retireCancelLeadReservation:['Retire This Lead', 'Cancel Reservation'],
    }
  },
  components: {
    ChatWindow,
    Todo,
    RetireLead,
    ContactsOverview,
    SendInformation,
    Status
  },
  props: ['reservation'],
  async created () {
    
    if(this.reservation){
      this.active_reservation = this.reservation;
    } else {
        await this.getActiveLeadsList();
    }

    await this.onTenantChange();
    this.summary_panel = 'chat'
    this.interval_id = window.setInterval(() => this.updateTimer(), 1000);
    EventBus.$on('hide_drawer', this.close);            
  },
  beforeDestroy () {
      window.clearInterval(this.interval_id);
      EventBus.$off('hide_drawer', this.close);            
  },
  computed: {
      ...mapGetters({
          properties: 'propertiesStore/filtered',
          hasPermission: 'authenticationStore/rolePermission',          
      }),
    activeLeasePhone () {
      if (!this.active_reservation || !this.active_reservation.Contact) return
      return this.active_reservation.Contact.Phones.length ? this.active_reservation.Contact.Phones[0].phone : ''
    },
    reservation_start(){
      return this.active_reservation.Lease.start_date
    },
    sms_enabled_phone () {

      if (!this.active_reservation) return null
      let obj = this.active_reservation.Contact ? this.active_reservation.Contact.Phones.find(x => x.sms == true) : null
      return obj ? obj.phone : null
    },
    has_reservation() {
      return this.hasReservation(this.active_reservation)
    },
    webLeadResEvent(){
      if (this.active_reservation && this.active_reservation.event) {
        return this.active_reservation.event;
      }
      return ''
    },
    computedContactTitle() {
      if(this.active_reservation && this.active_reservation.Contact){
        return (this.active_reservation.Contact.first ? this.active_reservation.Contact.first : '') + (this.active_reservation.Contact.middle ? ' ' + this.active_reservation.Contact.middle : '') + (this.active_reservation.Contact.last ? ' ' + this.active_reservation.Contact.last : '');
      } else {
        return undefined;
      }
    },
    computedContactPhone() {
      if(this.active_reservation && this.active_reservation.Contact && this.active_reservation.Contact.Phones && this.active_reservation.Contact.Phones[0] && this.active_reservation.Contact.Phones[0].phone){
        return this.active_reservation.Contact.Phones[0].phone;
      } else {
        return undefined;
      }
    },
    computedContactAddress() {
      if(this.active_reservation && this.active_reservation.Contact && this.active_reservation.Contact.Addresses && this.active_reservation.Contact.Addresses[0] && this.active_reservation.Contact.Addresses[0].Address){
        return (this.active_reservation.Contact.Addresses[0].Address.address ? this.active_reservation.Contact.Addresses[0].Address.address : '') + (this.active_reservation.Contact.Addresses[0].Address.address2 ? ' ' + this.active_reservation.Contact.Addresses[0].Address.address2 + ', ' : '') +  this.$options.filters.formatAddress(this.active_reservation.Contact.Addresses[0].Address); 
      } else {
        return undefined;
      }
    },
    computedPropertyInfo(){
      if(this.active_reservation && this.active_reservation.Lease && this.active_reservation.Lease.Unit && this.active_reservation.Lease.Unit.Address){
        return this.$options.filters.formatAddress(this.active_reservation.Lease.Unit.Address, '-', true);
      } else {
        return undefined;
      }
    }
  },


  methods: {
    ...mapActions({
      setTaskCenterOpen: 'taskCenterStore/setTaskCenterOpen',
    }),
    updateTimer (){
      if (!this.active_reservation) return;
      let time = '';
      if (this.has_reservation && this.webLeadResEvent) {
        time = this.webLeadResEvent.time || this.active_reservation.reservation_time;
      } else if(this.has_reservation) {
        time = this.active_reservation.reservation_time;
      } else {
        time = this.active_reservation.lead_time;
      }
      this.duration = this.calculateTimer(time)
      this.time_color = this.checkLeadCriteria(this.duration)

    },
    checkLeadCriteria(value){
      let timeArray = value.split(':')
      let hours = timeArray.length > 2 ? parseInt(timeArray[0]) : 0
      let minutes = timeArray.length > 2 ? parseInt(timeArray[1]) : parseInt(timeArray[0])

      if (hours >= 48) {
        return {
          color: this.leadCriteria.expired
        }
      } else if (hours > 0 || minutes >= 5) {
        return {
          color: this.leadCriteria.overDue
        }
      } else if (minutes <= 5 && minutes >= 0) {
        return {
          color: this.leadCriteria.acceptable
        }
      } else {
        return {
          color: this.leadCriteria.none
        }
      }
    },
    hasReservation(lead) {
      // if (lead && lead.reservation_id && lead.expires) {
      //   let expiryDate = moment.utc(lead.expires).local().format('YYYY-MM-DD')
      //   let now = moment().format('YYYY-MM-DD')
      //   return expiryDate >= now ? true : false
      // } else {
      //   return false
      // }
      return lead && lead.reservation_id && lead.expires? true: false;
    },
    onFocus(e){
      this.autoCompleteFocus = true
    },
    setReminder() {
      this.isReminder = true;
      this.reminderFlag = true;
      this.title_text = 'Create Task'
      this.btn_text = 'Create'
    },
    createTask() {
      this.isReminder = false;
      this.reminderFlag = true
      this.title_text = 'Create Task'
      this.btn_text = 'Create'
    },
    calculateTimer(value) {

      if(!value) return '00:00'
      let startDate = moment.utc(value).local()
      let endDate = moment()
      let diffInMilliSeconds = Math.abs(endDate - startDate) / 1000;

      const days = Math.floor(diffInMilliSeconds / 86400);
      diffInMilliSeconds -= days * 86400;

      let hours = (Math.floor(diffInMilliSeconds / 3600) % 24)
      diffInMilliSeconds -= hours * 3600;

      const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
      diffInMilliSeconds -= minutes * 60;

      const seconds = Math.floor(diffInMilliSeconds) % 60;

      let difference = '';
      // if (days > 0) {
      //   difference += days.toString().padStart(2, '0') + ':'
      // }
      hours += 24 * days;
      difference += hours.toString().padStart(2, '0') + ':'
      difference += minutes.toString().padStart(2, '0') + ':'
      difference += seconds.toString().padStart(2, '0')

      return difference

    },
    async getReservationInfo () {
      const reservation = this.active_reservation;

      try {
        let setup = await api.get(this, api.UNITS + reservation.unit_id + '/lease-set-up', {
        reservation_id: reservation.reservation_id,
        start_date: reservation.start_date ? reservation.start_date : reservation.Lease.start_date
      });
      this.is_lease_setup = true
      this.active_reservation.Setup = setup.details;
      } catch(error) {
        this.showMessageNotification({description: error})
      }
    },
    async getActiveLeadsList() {
      this.dataLoading = false;
      let response = await api.get(this, api.LEADS + 'reservations');
      this.tenantList = response.reservations.map((x,i) => {
        return {
          ...x,
          temp_id: i
        }
      })
      this.active_reservation = this.tenantList.length > 0 ? this.tenantList[0] : null;
    },
    sendNote () {
      this.validate(this).then(status => {
        if (!status) {
          return
        }
        var data = {
          content: this.noteContent,
          method: 'phone',
          created: new Date(),
          pinned: 0,
          context: 'leadFollowUp',
          tenant_answer: this.tenantAnswer,
          space : this.active_reservation?.Lease?.Unit?.number
        }
        api.post(this, api.CONTACTS + this.contact_id + '/interaction', data).then(note =>{

            this.noteContent = ''
            this.tenantAnswer = null
            this.summary_panel = 'chat'
            EventBus.$emit('communication::refresh');
            this.showMessageNotification({ type: 'success', description: 'Call record saved successfully.' });
            if (this.webLeadResEvent) {
              window.clearInterval(this.interval_id);
              this.dismissTask(this.webLeadResEvent.id);
            }
        })

      })
    },
    dismissTask(id){
      let event = {
        id
      };
      api.put(this, api.TODOS + '/dismiss', { todos: [event] }, 'none');
    },
    async onTenantChange () {
      this.dataLoading = false
      this.lease_id = this.active_reservation.lease_id
      this.contact_id = this.active_reservation.contact_id

      let time = this.has_reservation ? this.active_reservation.reservation_time: this.active_reservation.lead_time
      this.duration = this.calculateTimer(time)
      this.time_color = this.checkLeadCriteria(this.duration)

      this.$refs.autoCompleteId && this.$refs.autoCompleteId.blur();

      let result = await api.get(this, api.CONTACTS + this.active_reservation.contact_id)
      

      this.active_reservation.Contact = result.contact;

      let obj = this.active_reservation.Contact.Reservations.filter(r=> r.Lease.id === this.active_reservation.lease_id)
      this.active_reservation.Lease = obj.length > 0 ? obj[0].Lease : {}

      if (this.has_reservation) {
        await this.getReservationInfo()
      }

      this.panel = 0
      this.dataLoading = true
    },
    nextLead () {
      if (!this.active_reservation) {
        this.active_reservation = this.tenantList.length ? this.tenantList[0] : null
      } else {
        let index = this.active_reservation.temp_id + 1

        if (index >= this.tenantList.length) {
          this.active_reservation = null
        } else {
          this.active_reservation =  this.tenantList[index]
          this.panel = 0
          this.onTenantChange()
        }
      }
    },
    async sendRentalEmail(){
      await api.get(this, api.LEADS + this.contact_id + '/request-dossier');
      this.show_rental = false;
    },
    async sendRentalText(){
      await api.get(this, api.LEADS + this.contact_id + '/request-dossier');
      this.show_rental = false;
    },
    async newLead(location){
      if(this.has_reservation && this.active_reservation && this.is_lease_setup) {
        await this.$store.dispatch('onBoardingStore/getPropertyInfo', this.properties.find(p => p.id === this.active_reservation.Lease.Unit.property_id));
        let {  Contact, ...reservation } = this.active_reservation;
        reservation.id = this.active_reservation.reservation_id;
        await this.$store.dispatch('onBoardingStore/getReservation', {reservation, contact_id: this.contact_id});
      } else {
        if(this.contact_id){
          await this.$store.dispatch('onBoardingStore/getContactInfo', {contact_id: this.contact_id});
        }
      }

      await EventBus.$emit('new_lead', { location: location});

    },
    viewEntity(entity, entity_id){
      let params = {};
      params = {
        component: entity,
        props: {}
      };
      params.props[entity + '_id'] = entity_id;
      this.$store.dispatch('navigationStore/openSlideOut', params);
    },
    close(){
      this.setTaskCenterOpen(false);
      this.$emit('close');
    }
  },
    watch: {
        active_reservation() {
            if (this.active_reservation) {
                this.autoCompleteFocus = false
            }
        }
    },
}
</script>

<style scoped>

  .link-color {
    color: #306FB6;
  }
  .timer{
    font-style: normal;
    font-weight: 500;
    font-size: 45px;
    line-height: 150%;
  }
  .v-dialog {
   overflow-y: none;
  }

  .new-tenant-walkthrough-footer{
    padding: 0 !important;
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: 100;
    left: 0;
    right: 0;
  }
  span{
    color: #101318;
    font-family: 'Graphik Web', sans-serif;
  }
  .phone-log-header {
    background: linear-gradient(180deg, #FFFFFF 0%, #F9FAFB 100%);
    box-shadow: inset 0px -1px 0px rgba(11, 18, 29, 0.1);
  }
  .call-info {
    background: #FFF9DA;
    border: 1px solid #ffd600;
    box-sizing: border-box;
    border-radius: 4px;
  }

  .call-info-header {
    background: #E0F5F5;
    border: 1px solid #D0EDEC;
    box-sizing: border-box;
    border-radius: 4px;
  }

  .new-tenant-walkthrough-container{
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-items: stretch;
      height: 100%;
      flex: 1 1;
      position: relative;
      overflow: hidden;
  }
  .new-tenant-walkthrough-content-section{
      align-items:stretch;
      display: flex;
      overflow: hidden;
      padding-bottom: 0px;
      height: 100%;
  }
  .new-tenant-walkthrough{
      background: #F9FAFB;
      width: 100%;
      overflow: hidden !important;
  }

  .new-tenant-info-panel{
      flex: 0 0 420px;
      height: 100%;
      /* height: 80vh; */
      overflow:auto;
      background-color: #F9FAFB;
      border-left: 1px solid #E1E6EA;
      padding-bottom: 60px;
  }
  /* .walkthrough-body{
      flex: 1;
      overflow: auto;
      overflow-y: scroll;
      height: 80vh;
  }  */

  .nav-drawer-header{
      flex: 0 1 auto;
      min-height:72px;
      background: #FFFFFF;
  }
  .onboarding-container{
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-items: stretch;
      flex: 1;
  }

  /* @media (min-height: 700px) {
    .walkthrough-body{
       height: 82vh;
    }
    .new-tenant-walkthrough-content-section{
      padding-bottom: 47px !important;
    }
    .new-tenant-info-panel {
      height: 80vh;
    }
  }

  @media (min-height: 800px) {
    .walkthrough-body{
      height: 84vh;
    }
    .new-tenant-info-panel {
      height: 84vh;
    }
  }

  @media (min-height: 900px) {
    .walkthrough-body{
      height: 86vh;
    }
    .new-tenant-info-panel {
      height: 86vh;
    }
  }

  @media (min-height: 1025px) {
    .walkthrough-body{
      height: 88vh;
    }
    .new-tenant-walkthrough-content-section{
      padding-bottom: 42px !important;
    }
    .new-tenant-info-panel {
      height: 88vh;
    }
  } */

</style>
