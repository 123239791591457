<template>
    <div class="section-content ma-0 pa-0 edit-template-config h-100">
        <v-row class="ma-0 main-section-content" style="background-color: #F9FAFB;">
            <v-col class="left-panel">
                <div class="left-preview">
                    <v-row no-gutters>
                        <v-col>
                            <span class="text-h6"> {{ currentItem.isNew ? 'Create Template' : 'Edit Template' }}</span>
                        </v-col>
                    </v-row>
                    <v-divider class="mt-5"></v-divider>
                </div>
                <v-expansion-panels ref="templateSettingRef" v-model="templateSettings"
                    v-if="currentItem?.item?.type === 'email' || 'sms' || currentItem.isNew">
                    <hb-expansion-panel :top-margin="true">
                        <template v-slot:title>
                            Template Settings
                        </template>
                        <template v-slot:content>
                            <hb-form label="Template Name"
                                description="How this template will be listed in template list" full required>
                                <HbTextField v-model="config.name" v-validate="'required|max:50'" data-vv-name="name"
                                    data-vv-scope="general" data-vv-as="Template Name"
                                    :disabled="Boolean(currentItem?.item?.is_base_template || currentItem?.propertyId)"
                                    :error="errors.has('general.name')" placeholder="Template Name" class="mb-2" />
                            </hb-form>
                            <hb-form label="Template Category" description="Select the category of the template" full
                                required>
                                <HbSelect v-model="config.category" :items="items" v-validate="{ required: true }"
                                    data-vv-scope="general" data-vv-name="category" data-vv-as="Template Type"
                                    :error="errors.has('general.category')" placeholder="Select Category"
                                    :disabled="Boolean(currentItem?.item?.is_base_template || currentItem?.propertyId)"
                                    class="mb-3" />
                            </hb-form>
                            <hb-form label="Status" full v-if="!currentItem.isNew">
                                <div class="mr-1 d-flex">
                                    <HbIcon :mdi-code="TEMPLATE.ICONS[currentItem?.item?.templateStatus]?.icon" small
                                        :color="TEMPLATE.ICONS[currentItem?.item?.templateStatus]?.icon_color" />
                                    <div v-html="TEMPLATE.ICONS[currentItem?.item?.templateStatus]?.name"
                                        class="font-weight-medium pl-2"></div>
                                </div>

                            </hb-form>
                            <hb-form label="Delivery Type" full required description="Select the Delivery Type">
                                <HbSelect v-model="config.type" :items="itemsTypes" v-validate="{ required: true }"
                                    data-vv-scope="general" data-vv-name="type" data-vv-as="Message Type"
                                    :disabled="Boolean(!currentItem.isNew)" :error="errors.has('general.type')"
                                    placeholder="Select Delivery Type" class="mb-3" />
                                <p v-if="config.type == 'email'">Subject Line:</p>
                                <HbTextField v-model="config.email_subject" v-if="config.type == 'email'"
                                    v-validate="{ required: true }" id="messageSubject" class="mb-3 mt-2"
                                    :disabled="Boolean(currentItem?.item?.is_base_template)" name="messageSubject"
                                    data-vv-scope="general" data-vv-as="MessageSubject"
                                    :error="errors.has('general.messageSubject')" placeholder="Email Subject" />
                            </hb-form>
                            <hb-form label="Enable Properties" full v-if="false">
                                <hb-combobox style="max-width: 720px !important;" v-model="config.properties"
                                    v-validate="validationRule" editable :items="filterPropertyList"
                                    data-vv-scope="general" data-vv-name="property" data-vv-as="properties"
                                    item-text="name" label="Select properties"
                                    :error="errors.has('general.property')" />
                            </hb-form>
                        </template>
                    </hb-expansion-panel :top-margin="true">
                </v-expansion-panels>

                <!-- rental and reservation section -->
                <!-- as per suggestions (risk string matching using regular expression)-->
                <v-expansion-panels v-model="templateScenarios" v-if="matchesPattern(currentItem, 'generic')">
                    <hb-expansion-panel>
                        <template v-slot:title>
                            Template Scenarios
                        </template>
                        <template v-slot:content>
                            <!-- as per suggestions (risk string matching using regular expression)-->
                            <!-- <div v-if="matchesPattern(currentItem, 'rental_confirmation')"">
                                <hb-form label="Access Code(2 Scenarios)" full required>
                                    <span class="hb-font-caption">Access Code Displayed</span>
                                    <HbTextarea search v-model="codeDisplayed" :rows="4" auto-grow
                                        v-validate="'required|max:1000'" data-vv-scope="default" data-vv-name="message"
                                        data-vv-as="Message" :error="errors.has('default.message')" class="mt-2"
                                        placeholder="Enter Message" placeholder-fix />
                                    <span class="hb-font-caption">Access Code not Displayed</span>
                                    <HbTextarea search v-model="codeNotDisplayed" :rows="4" auto-grow
                                        v-validate="'required|max:1000'" data-vv-scope="default" data-vv-name="message"
                                        data-vv-as="Message" :error="errors.has('default.message')" class="mt-2"
                                        placeholder="Enter Message" placeholder-fix />
                                </hb-form>
                            </div> -->
                            <hb-form label="What’s the next step?" full required>
                                <!-- as per suggestions (risk string matching using regular expression)-->
                                <div v-if="matchesPattern(currentItem, 'rental_confirmation')">
                                    <span class="hb-font-caption">Identity Verification Success:</span>
                                    <rich-text-editor id="success" :excludeTags="['image', 'code', 'quote', 'clear']"
                                        class="email-rich-editor mt-2" v-model="successIDV.notes.en"
                                        proseHeight="200px" />
                                    <span class="hb-font-caption">Identity Verification Disabled:</span>
                                    <rich-text-editor id="disabled" :excludeTags="['image', 'code', 'quote', 'clear']"
                                        class="email-rich-editor mt-2" v-model="disabledIDV.notes.en"
                                        proseHeight="200px" />
                                    <span class="hb-font-caption">Identity Verification Skipped/Failed:</span>
                                    <rich-text-editor id="failed" :excludeTags="['image', 'code', 'quote', 'clear']"
                                        class="email-rich-editor mt-2" v-model="skippedIDV.notes.en"
                                        proseHeight="200px" />
                                    <!-- <span class="hb-font-caption">Rental Failure:</span>
                                    <rich-text-editor id="failure" class="email-rich-editor mt-2"
                                        v-model="failureRental" proseHeight="200px" /> -->
                                </div>
                                <!-- as per suggestions (risk string matching using regular expression)-->
                                <div v-if="matchesPattern(currentItem, 'reservation_success')">
                                    <span class="hb-font-caption">Reservation Success:</span>
                                    <rich-text-editor :excludeTags="['image', 'code', 'quote', 'clear']"
                                        id="reservation-success" class="email-rich-editor mt-2"
                                        v-model="successReservation.notes.en" proseHeight="200px" />
                                </div>
                                <!-- as per suggestions (risk string matching using regular expression)-->
                                <div v-if="matchesPattern(currentItem, 'reservation_failure')">
                                    <span class="hb-font-caption">Reservation Failure:</span>
                                    <rich-text-editor :excludeTags="['image', 'code', 'quote', 'clear']"
                                        id="reservation-failure" class="email-rich-editor mt-2"
                                        v-model="failureReservation.notes.en" proseHeight="200px" />
                                </div>
                            </hb-form>
                        </template>
                    </hb-expansion-panel>
                </v-expansion-panels>
                <!-- rental and reservation section -->

                <!-- non rental and reservation section -->
                <div v-else ref="nonRentalReservationRef">
                    <div v-if="currentItem.isNew">
                        <div v-if="currentItem?.item?.type === 'email' || config?.type == 'email'">
                            <div class="content-wrapper-rich-text">
                                <div>
                                    <template
                                        v-if="currentItem?.item?.is_base_template == 1 || currentItem?.item?.base_template_id != null">
                                        <MceEditor v-model="content" :height="700" v-bind="editorProps" />
                                    </template>
                                    <template v-else>
                                        <rich-text-editor ref="richTextEditor" class="email-rich-editor"
                                            v-model="content" proseHeight="450px" />
                                    </template>
                                </div>

                                <div class="ma-0 pa-2">

                                    <div class="hb-bab-wrapper d-flex align-center">
                                        <div class="hb-bab-left-actions-wrapper">
                                            <div class="d-flex bottom-action">
                                                <hb-btn color="secondary" @click="handleAddMergeClick(currentItem?.item?.is_base_template == 1 || currentItem?.item?.base_template_id != null
                                                    ? 'template_manager_mce_email'
                                                    : 'template_manager_rich_email')">Add
                                                    Merge
                                                    Fields</hb-btn>
                                                <hb-tooltip>
                                                    <template v-slot:body>
                                                        Merge fields are placeholders in templates that are <br />
                                                        replaced
                                                        with
                                                        data when the template is used
                                                    </template>
                                                </hb-tooltip>
                                            </div>
                                        </div>
                                        <v-spacer></v-spacer>
                                        <div class="d-flex align-center pr-0 hb-bab-right-actions-wrapper">
                                            <span class="hb-bab-right-actions-wrapper-span">
                                                <hb-btn color="secondary" @click="previewContent()">Preview</hb-btn>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <div v-if="currentItem?.item?.type === 'email'" class="mb-10">
                            <div class="content-wrapper-rich-text">
                                <div>
                                    <template
                                        v-if="currentItem?.item?.is_base_template == 1 || currentItem?.item?.base_template_id != null">
                                        <MceEditor v-model="content" :height="400" v-bind="editorProps" />
                                    </template>
                                    <template v-else>
                                        <rich-text-editor ref="richTextEditor" class="email-rich-editor"
                                            v-model="content" proseHeight="400px" />
                                    </template>
                                </div>
                                <div class="ma-0 pa-2">

                                    <div class="hb-bab-wrapper d-flex align-center">
                                        <div class="hb-bab-left-actions-wrapper">
                                            <div class="d-flex bottom-action">
                                                <hb-btn color="secondary" @click="handleAddMergeClick(currentItem?.item?.is_base_template == 1 || currentItem?.item?.base_template_id != null
                                                    ? 'template_manager_mce_email'
                                                    : 'template_manager_rich_email')">Add
                                                    Merge
                                                    Fields</hb-btn>
                                                <hb-tooltip>
                                                    <template v-slot:body>
                                                        Merge fields are placeholders in templates that are <br />
                                                        replaced
                                                        with
                                                        data when the template is used
                                                    </template>
                                                </hb-tooltip>
                                            </div>
                                        </div>
                                        <v-spacer></v-spacer>
                                        <div class="d-flex align-center pr-0 hb-bab-right-actions-wrapper">
                                            <span class="hb-bab-right-actions-wrapper-span">
                                                <hb-btn color="secondary" @click="previewContent()">Preview</hb-btn>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div v-if="currentItem.isNew">
                        <v-expansion-panels v-model="panelSms"
                            v-if="currentItem?.item?.type == 'sms' || config?.type == 'sms'" multiple>
                            <hb-expansion-panel :top-margin="true">

                                <template v-slot:title>
                                    Content
                                </template>

                                <template v-slot:content>
                                    <div class="mt-3 mb-3 ml-3">
                                        <!-- <rich-text-editor class="email-rich-editor" v-model="smsMessage" proseHeight="300px"/> -->
                                        <v-textarea v-model="smsMessage" hide-details no-resize></v-textarea>
                                    </div>
                                </template>

                                <template v-slot:footer>
                                    <hb-bottom-action-bar cancel-off>
                                        <template v-slot:left-actions>
                                            <div class="d-flex bottom-action">
                                                <hb-btn color="secondary"
                                                    @click="handleAddMergeClick('template_manager_sms')">Add Merge
                                                    Fields</hb-btn>
                                                <hb-tooltip>
                                                    <template v-slot:body>
                                                        Merge fields are placeholders in templates that are <br />
                                                        replaced
                                                        with
                                                        data when the template is used
                                                    </template>
                                                </hb-tooltip>
                                            </div>
                                        </template>

                                        <template v-slot:right-actions>
                                            <hb-btn color="secondary" @click="previewSMS()">Preview</hb-btn>
                                        </template>
                                    </hb-bottom-action-bar>
                                </template>
                                <div class="ma-0 pa-2">

                                    <div class="hb-bab-wrapper d-flex align-center">
                                        <div class="hb-bab-left-actions-wrapper">
                                            <div class="d-flex bottom-action">
                                                <hb-btn color="secondary"
                                                    @click="handleAddMergeClick('template_manager_sms')">Add Merge
                                                    Fields</hb-btn>
                                                <hb-tooltip>
                                                    <template v-slot:body>
                                                        Merge fields are placeholders in templates that are <br />
                                                        replaced
                                                        with
                                                        data when the template is used
                                                    </template>
                                                </hb-tooltip>
                                            </div>
                                        </div>
                                        <v-spacer></v-spacer>
                                        <div class="d-flex align-center pr-0 hb-bab-right-actions-wrapper">
                                            <span class="hb-bab-right-actions-wrapper-span">
                                                <hb-btn color="secondary" @click="previewSMS()">Preview</hb-btn>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </hb-expansion-panel>
                        </v-expansion-panels>
                    </div>
                    <div v-else>
                        <v-expansion-panels v-model="panelSms" v-if="currentItem?.item?.type == 'sms'" multiple>
                            <hb-expansion-panel :top-margin="true">

                                <template v-slot:title>
                                    Content
                                </template>

                                <template v-slot:content>
                                    <div class="mt-3 mb-3 ml-3">
                                        <v-textarea v-model="smsMessage" hide-details no-resize></v-textarea>
                                    </div>
                                </template>

                                <template v-slot:footer>
                                    <hb-bottom-action-bar cancel-off>
                                        <template v-slot:left-actions>
                                            <div class="d-flex bottom-action">
                                                <hb-btn color="secondary"
                                                    @click="handleAddMergeClick('template_manager_sms')">Add Merge
                                                    Fields</hb-btn>
                                                <hb-tooltip>
                                                    <template v-slot:body>
                                                        Merge fields are placeholders in templates that are <br />
                                                        replaced
                                                        with
                                                        data when the template is used
                                                    </template>
                                                </hb-tooltip>
                                            </div>
                                        </template>

                                        <template v-slot:right-actions>
                                            <hb-btn color="secondary" @click="previewSMS()">Preview</hb-btn>
                                        </template>
                                    </hb-bottom-action-bar>
                                </template>
                            </hb-expansion-panel>
                        </v-expansion-panels>
                    </div>
                </div>
                <!-- non rental and reservation section -->
            </v-col>
            <v-col class="right-panel">
                <div class="right-preview">
                    <v-row no-gutters>
                        <v-col>
                            <span class="text-h6"> Preview Template</span>
                        </v-col>
                        <v-col class="align-right">
                            <hb-tabs v-model="responsiveTab" right>
                                <v-tab :ripple="false" key="1">
                                    Desktop
                                </v-tab>
                                <v-tab :ripple="false" key="2">
                                    Mobile
                                </v-tab>
                            </hb-tabs>
                        </v-col>
                    </v-row>
                    <v-divider></v-divider>
                    <hb-notification v-model="warningNotification" v-if="warningNotification" type="caution"
                        class="pa-3">
                        The data displayed on this preview screen is for illustrative purposes only and may
                        not
                        reflect the actual data.
                    </hb-notification>
                    <div v-if="config?.type === 'email' || currentItem?.item?.type === 'email'"
                        :class="{ 'hb-elevation': filteredMail && filteredMail.trim() !== '' && filteredMail.trim() !== '<p></p>' }"
                        class="mt-3 mx-auto d-flex justify-center" :style="dynamicStyle">
                        <iframe
                            v-if="filteredMail && filteredMail.trim() !== '' && filteredMail.trim() !== '<p></p>' && !matchesPattern(currentItem, 'generic')"
                            :srcdoc="filteredMail" class="tm-content" style="width: 92%; border: 1px solid #DFE3E8;"
                            ref="dynamicIframe" @load="adjustIframeHeight">
                        </iframe>

                        <!-- only for rental and reservation -->
                        <iframe class="pa-6 tm-content"
                            :srcdoc="currentItem?.data?.sampleData?.preview || `<span class='d-flex justify-center'>No preview data available</span>`"
                            v-else-if="currentItem?.item?.is_base_template"
                            style="display: block; width: 100%; height: 100vh; height: 100svh;border: none;">
                        </iframe>
                        <!-- only for rental and reservation -->
                        <HbEmptyState v-else message="No preview has been created yet." />
                    </div>
                    <div v-else>
                        <div v-html="sms" class="pa-5 mt-5 mx-auto tm-content hb-elevation-small"
                            v-if="sms && sms.trim() !== ''" :style="dynamicStyle">
                        </div>
                        <HbEmptyState v-else message="No preview has been created yet." class="mt-3" />
                    </div>
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script type="text/babel">
import { mapGetters, mapMutations, mapActions, mapState } from 'vuex';
import api from '../../../../assets/api.js';
import TabSection from '../../../includes/Tabs/Index.vue';
import RichTextEditor from "../../../assets/RichTextEditor.vue";
import MceEditor from "../../../assets/MceEditor.vue";
import { notificationMixin } from '../../../../mixins/notificationMixin.js';
import templateConfig from './templateConfig.vue'
import { cloneDeep } from "lodash";
import TEMPLATE from '@/constants/template.js';
export default {
    name: "edit-preview",
    expose: ['getData', 'getConfigData'],
    mixins: [notificationMixin],
    props: ['currentItem'],
    data() {
        return {
            area: '',
            TEMPLATE: '',
            warningNotification: false,

            PREVIEW_TEMPLATE: {},
            codeDisplayed: '',
            codeNotDisplayed: '',

            successIDV: {
                id: '',
                source: 'Tenant Website',
                email_type: "Rental Mail",
                notes: { en: "", es: "" },
                email_notes_idv: "idv_enabled",
                html_enabled: true,
            },
            successIDVChanged: false,
            originalSuccessIDV: {
                id: '',
                source: 'Tenant Website',
                email_type: "Rental Mail",
                notes: { en: "", es: "" },
                email_notes_idv: "idv_enabled",
                html_enabled: true,
            },
            emptySuccessIDV: false,


            disabledIDV: {
                id: '',
                source: 'Tenant Website',
                email_type: "Rental Mail",
                notes: { en: "", es: "" },
                email_notes_idv: "idv_disabled",
                html_enabled: true,
            },
            disabledIDVChanged: false,
            originalDisabledIDV: {
                id: '',
                source: 'Tenant Website',
                email_type: "Rental Mail",
                notes: { en: "", es: "" },
                email_notes_idv: "idv_disabled",
                html_enabled: true,
            },
            emptyDisabledIDV: false,

            skippedIDV: {
                id: '',
                source: 'Tenant Website',
                email_type: "Rental Mail",
                notes: { en: "", es: "" },
                email_notes_idv: "idv_in_store",
                html_enabled: true,
            },
            skippedIDVChanged: false,
            originalSkippedIDV: {
                id: '',
                source: 'Tenant Website',
                email_type: "Rental Mail",
                notes: { en: "", es: "" },
                email_notes_idv: "idv_in_store",
                html_enabled: true,
            },
            emptySkippedIDV: false,

            successChanged: false,
            failureChanged: false,
            originalFailureReservation: {
                id: '',
                source: 'Tenant Website',
                email_type: "Reservation Failure",
                notes: { en: "", es: "" },
                email_notes_idv: "",
                html_enabled: true,
            },
            originalSuccessReservation: {
                id: '',
                source: 'Tenant Website',
                email_type: "Reservation Mail",
                notes: { en: "", es: "" },
                email_notes_idv: "",
                html_enabled: true,
            },

            successReservation: {
                id: '',
                source: 'Tenant Website',
                email_type: "Reservation Mail",
                notes: { en: "", es: "" },
                email_notes_idv: "",
                html_enabled: true,
            },
            emptySuccessReservation: false,
            failureReservation: {
                id: '',
                source: 'Tenant Website',
                email_type: "Reservation Failure",
                notes: { en: "", es: "" },
                email_notes_idv: "",
                html_enabled: true,
            },
            emptyFailureReservation: false,

            propertyEmailNotes: [],
            emptyData: false,

            originalConfig: {},
            config: {
                name: '',
                category: 'basic',
                type: '',
                email_subject: '',
                properties: [],
                properties_backup: [],
            },
            itemsTypes: [
                { text: 'SMS', value: 'sms' },
                { text: 'Email', value: 'email' },
            ],
            // TODO : its should be in backend
            items: [
                { text: 'Rent Change', value: 'rent_change' },
                { text: 'Delinquency', value: 'deliquency' },
                { text: 'Maintenance', value: 'maintenance' },
                { text: 'Alerts', value: 'alerts' },
                { text: 'General', value: 'general' },
                { text: 'Reservation', value: 'reservation' },
                { text: 'Rental', value: 'rental' },
                { text: 'Payment', value: 'payment' },
                { text: 'Notification', value: 'notification' }
            ],
            templateSettings: 0,
            templateScenarios: 0,
            Panel: [0, 1],
            panelSms: [0],
            responsiveTab: 0,
            header: {
                content: ``,
                toogle: true,
            },
            footer: {
                content: "",
                toogle: true
            },
            originalContent: ``,
            originalSmsContent: ``,
            content: ``,
            smsMessage: ``,
            sms: '', // only to render
            mail: '', // only to render
            html: ``,
            loading: false,
            showTags: false,
            editorProps: {
                height: 700,
                plugins: [
                    'autolink fullpage lists link image anchor preview',
                    'code',
                    'insertdatetime media paste code wordcount noneditable importcss',
                ],
                toolbar:
                    'image code undo redo | formatselect | bold italic backcolor | link | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent',
                menubar: false,
                resize: true,
                removed_menuitems: 'table'
            }
        };
    },
    components: {
        TabSection,
        RichTextEditor,
        'MceEditor': MceEditor,
        templateConfig,
    },
    computed: {
        ...mapGetters({
            facilityList: 'propertiesStore/filtered',

            selectedMergeField: 'addMergeFieldsStore/selectedMergeField',
            actionSource: 'addMergeFieldsStore/actionSource',
            addMergeButtonClickFlag: 'addMergeFieldsStore/addMergeButtonClickFlag',
        }),

        validationRule() {
            // Dynamic validation rule based on whether `currentItem` matches 'generic'
            const isGeneric = this.matchesPattern(this.currentItem, 'generic');
            return { required: !isGeneric };
        },

        filteredMail: function () {
            // Remove all content inside {% ... %} from mail
            // if (this.currentItem.item) return this.mail.replace(/{%(.*?)%}/g, "");
            // else 
            return this.mail
        },
        checkIfCreate() {
            if (!this.currentItem) {
                // If currentItem is not present(new create)
                return false;
            }
            else {
                // If this.currentItem exists, check if its propertyId is not an empty string
                if (!this.currentItem?.propertyId) {
                    // If propertyId is an empty string
                    return false
                } else {
                    // If propertyId is not an empty string
                    return true
                }
            }
        },

        filterPropertyList() {
            return this.facilityList.map(obj => ({ id: obj.id, name: obj.name }));
        },

        shouldShowRichEditor() {
            const checkIfCreate = this.checkIfCreate;
            const currentItem = this.currentItem; // Assuming currentItem is available in your component

            return checkIfCreate || !currentItem?.item?.is_base_template || !currentItem?.item?.base_template_id || currentItem?.item?.base_template_id !== null;
        },

        dynamicStyle() {
            if (this.responsiveTab === 0) {
                return 'width:100%'
            } else {
                return 'width:479px;'
            }

        },
        checkIfCreate() {
            if (this.currentItem) {
                return false
            }
            else return true
        },
    },
    watch: {
        // this is not scalable approach but due to high level requirement and suggestions 
        skippedIDV: {
            handler(newVal) {
                const isSame = newVal.notes.en.replace(/^<p>/, '').replace(/<\/p>$/, '') === this.originalSkippedIDV.notes.en.replace(/^<p>/, '').replace(/<\/p>$/, '');
                // Emit event if content has changed
                this.skippedIDVChanged = !isSame; //
                this.$emit('content-changed', !isSame);
            },
            deep: true
        },
        disabledIDV: {
            handler(newVal) {
                const isSame = newVal.notes.en.replace(/^<p>/, '').replace(/<\/p>$/, '') === this.originalDisabledIDV.notes.en.replace(/^<p>/, '').replace(/<\/p>$/, '');
                // Emit event if content has changed
                this.disabledIDVChanged = !isSame; //
                this.$emit('content-changed', !isSame);
            },
            deep: true
        },
        successIDV: {
            handler(newVal) {
                const isSame = newVal.notes.en.replace(/^<p>/, '').replace(/<\/p>$/, '') === this.originalSuccessIDV.notes.en.replace(/^<p>/, '').replace(/<\/p>$/, '');
                // Emit event if content has changed
                this.successIDVChanged = !isSame; //
                this.$emit('content-changed', !isSame);
            },
            deep: true
        },
        successReservation: {
            handler(newVal) {
                const isSame = newVal.notes.en.replace(/^<p>/, '').replace(/<\/p>$/, '') === this.originalSuccessReservation.notes.en.replace(/^<p>/, '').replace(/<\/p>$/, '');
                // Emit event if content has changed
                this.successChanged = !isSame; //
                this.$emit('content-changed', !isSame);
            },
            deep: true
        },
        failureReservation: {
            handler(newVal) {
                const isSame = newVal.notes.en.replace(/^<p>/, '').replace(/<\/p>$/, '') === this.originalFailureReservation.notes.en.replace(/^<p>/, '').replace(/<\/p>$/, '');
                // Emit event if content has changed
                this.failureChanged = !isSame; // Track if failureReservation has changed
                this.$emit('content-changed', !isSame);
            },
            deep: true
        },
        content: {
            handler(newVal) {
                const isSame = newVal === this.originalContent;
                this.$emit('content-changed', !isSame);
            },
        },
        config: {
            handler(newVal) {
                const isSame = JSON.stringify(newVal) === JSON.stringify(this.originalConfig);
                this.$emit('content-changed', !isSame);
            },
            deep: true
        },
        smsMessage: {
            handler(newVal) {
                const isSame = newVal === this.originalSmsContent;
                this.$emit('content-changed', !isSame);
            },
        },
        selectedMergeField: {
            handler(newVal) {
                if (newVal) {
                    this.addMergeFieldToEditor(newVal)
                }
            }
        },
    },
    created() {
        this.TEMPLATE = TEMPLATE;
    },
    mounted() {
        this.loadData()
        // only for rental and reservation starts
        if (this.matchesPattern(this.currentItem, 'generic')) this.loadEmailNotes();
        this.warningNotification = this.matchesPattern(this.currentItem, 'generic')
        // only for rental and reservation ends

        this.originalContent = this.content;
        this.originalSmsContent = this.smsMessage
        this.originalConfig = JSON.parse(JSON.stringify(this.config));
    },
    methods: {
        ...mapActions({
            setTriggerMergeFields: 'addMergeFieldsStore/setTriggerMergeFields',
            setActionSource: 'addMergeFieldsStore/setActionSource',
            setFilterInput: "addMergeFieldsStore/setFilterInput",
            resetMergeFieldParameters: "addMergeFieldsStore/reset",
        }),
        addMergeFieldToEditor(val) {
            if (this.actionSource !== 'template_manager_email' || !this.addMergeButtonClickFlag) {
                console.warn('Action source or button click flag condition not met.');
                return;
            }
            try {
                switch (this.area) {
                    case 'template_manager_mce_email': {
                        const editor = tinymce?.activeEditor;
                        if (editor) {
                            editor.insertContent(val);
                            this.$store.commit('addMergeFieldsStore/SET_SELECTED_MERGE_FIELD', null);
                        } else {
                            console.error('TinyMCE editor is not initialized or active.');
                        }
                        break;
                    }

                    case 'template_manager_rich_email':
                        const editorInstance = this.$refs.richTextEditor?.editor;
                        if (editorInstance) {
                            editorInstance.commands.insertContent(val);
                            this.content = editorInstance.getHTML();
                        } else {
                            console.error("Editor instance not found!");
                        }
                        this.$store.commit('addMergeFieldsStore/SET_SELECTED_MERGE_FIELD', null);
                        break;

                    case 'template_manager_sms':
                        this.smsMessage = this.smsMessage ? this.smsMessage + val : val;
                        this.$store.commit('addMergeFieldsStore/SET_SELECTED_MERGE_FIELD', null);
                        break;

                    default:
                        console.warn(`Unhandled area: ${this.area}`);
                }
            } catch (error) {
                this.resetMergeFieldParameters()
                console.error('An error occurred in addMergeFieldToEditor:', error);
            }
        },
        handleAddMergeClick(area) {
            this.area = area
            // don't change this unless and until you know what you are doing
            this.setTriggerMergeFields({
                actionSource: 'template_manager_email',
                addMergeButtonClickFlag: true
            })
        },
        adjustIframeHeight() {
            const iframe = this.$refs.dynamicIframe;
            if (!iframe) return;

            // Set a default height if  custom templates
            if (!this.currentItem?.item?.is_base_template && this.currentItem?.item?.is_base_template !== 1) {
                    // Get the iframe's internal content height
                    const iframeBody = iframe.contentWindow.document.body;
                    const iframeContentHeight = iframeBody?.scrollHeight || 0;
                    const iframeHeight = Math.min(iframeContentHeight + 200, 500);
                    iframe.style.height = `${iframeHeight}px`;
            }

            if (this.currentItem.isNew) iframe.style.height = "500px";

            // set dynamic height for system generated and reservation/rental
            if (iframe.contentWindow && this.currentItem?.item?.is_base_template == 1) {
                const nonRentalHeight = this.$refs.nonRentalReservationRef?.offsetHeight || 0;
                const templateHeight = this.$refs.templateSettingRef?.offsetHeight || 0;
                const totalHeight = nonRentalHeight + templateHeight;
                const iframeHeight = Math.max(totalHeight, 800); // Ensure a minimum height of 700px
                iframe.style.height = `${iframeHeight}px`;
            }
        }
        ,
        // as per higher suggestions not efficient code
        matchesPattern(item, patternKey) {
            const patterns = {
                rental_confirmation: /^Rental Confirmation - (Online|Instore)$/i,
                reservation_success: /^Reservation Success - (Online|Instore)$/i,
                reservation_failure: /^Reservation Failure - (Online|Instore)$/i,

                reservation_success_online: /^Reservation Success - Online$/i,
                reservation_success_instore: /^Reservation Success - Instore$/i,

                reservation_failure_online: /^Reservation Failure - Online$/i,
                reservation_success_instore: /^Reservation Failure - Instore$/i,

                rental_confirmation_online: /^Rental Confirmation - Online$/i,
                rental_confirmation_instore: /^Rental Confirmation - Instore$/i,
                generic: /^(Reservation|Rental) (Failure|Success|Confirmation) - (Online|Instore)$/i
            };

            if (!item?.item?.name || !item?.item?.is_base_template) {
                return false; // Ensure item and required fields are valid
            }

            return patterns[patternKey]?.test(item.item.name) || false;
        },
        // rental and reservation section start
        async loadEmailNotes() {
            let resp;
            try {
                // Fetch the email notes based on propertyId
                if (this.currentItem?.propertyId) {
                    resp = await api.get(this, api.TEMPLATE_MANAGER + `template/email-notes` + `?property_id=${this.currentItem?.propertyId}`);
                } else {
                    resp = await api.get(this, api.TEMPLATE_MANAGER + `template/email-notes`);
                }
                // this is not scalable approach but due to high level requirement and suggestions 
                if (Array.isArray(resp)) {
                    this.propertyEmailNotes = Array.isArray(resp)
                        ? resp.filter(note => note.source === 'Tenant Website')
                        : [];
                    // Check for specific reservation types and assign notes accordingly
                    if (/^Reservation Success - (Online|Instore)$/i.test(this.currentItem?.item?.name)) {
                        const foundNote = this.propertyEmailNotes.find(note => note.email_type === 'Reservation Mail');
                        if (foundNote) {
                            this.successReservation = foundNote ?? '';
                        } else {
                            this.emptySuccessReservation = true
                        }
                        this.originalSuccessReservation = cloneDeep(this.successReservation);
                    }
                    if (/^Reservation Failure - (Online|Instore)$/i.test(this.currentItem?.item?.name)) {
                        const foundNote = this.propertyEmailNotes.find(note => note.email_type === 'Reservation Failure');
                        if (foundNote) {
                            this.failureReservation = foundNote ?? '';
                        } else {
                            this.emptyFailureReservation = true
                        }
                        this.originalFailureReservation = cloneDeep(this.failureReservation);
                    }
                    if (/^Rental Confirmation - (Online|Instore)$/i.test(this.currentItem?.item?.name)) {
                        const localSuccessIDV = this.propertyEmailNotes.find(note => note.email_type === 'Rental Mail' && note.email_notes_idv === 'idv_enabled');
                        if (localSuccessIDV) {
                            this.successIDV = localSuccessIDV ?? '';
                        } else {
                            this.emptySuccessIDV = true;
                        }
                        this.originalSuccessIDV = cloneDeep(this.successIDV);

                        const localDisabledIDV = this.propertyEmailNotes.find(note => note.email_type === 'Rental Mail' && note.email_notes_idv === 'idv_disabled');
                        if (localDisabledIDV) {
                            this.disabledIDV = localDisabledIDV ?? '';
                        } else {
                            this.emptyDisabledIDV = true;
                        }
                        this.originalDisabledIDV = cloneDeep(this.disabledIDV);


                        const localSkippedIDV = this.propertyEmailNotes.find(note => note.email_type === 'Rental Mail' && note.email_notes_idv === 'idv_in_store');
                        if (localSkippedIDV) {
                            this.skippedIDV = localSkippedIDV ?? '';
                        } else {
                            this.emptySkippedIDV = true;
                        }
                        this.originalSkippedIDV = cloneDeep(this.skippedIDV);
                    }
                } else {
                    if (resp.code === 400) {
                        this.emptyData = true
                    }
                }
            } catch (error) {
                console.error("Error loading email notes:", error);
                // Optionally display an error message to the user
            }
        },
        getEmailNotes() {
            //    as per suggestions (risk string matching using regular expression)
            if (/^Reservation Success - (Online|Instore)$/i.test(this.currentItem?.item?.name)) {
                const notes = {};
                // Add success note only if it has changed
                if (this.successChanged && this.successReservation) {
                    notes.success = this.successReservation;
                    if (this.emptyData || this.emptySuccessReservation) {
                        delete notes.success.id;
                    }
                }
                // Return the notes object only if it contains any keys
                return Object.keys(notes).length > 0 ? notes : null;
            }
            if (/^Reservation Failure - (Online|Instore)$/i.test(this.currentItem?.item?.name)) {
                const notes = {};
                // Add failure note only if it has changed
                if (this.failureChanged && this.failureReservation) {
                    notes.failure = this.failureReservation;
                    if (this.emptyData || this.emptyFailureReservation) {
                        delete notes.failure.id;
                    }
                }
                return Object.keys(notes).length > 0 ? notes : null;
            }

            if (/^Rental Confirmation - (Online|Instore)$/i.test(this.currentItem?.item?.name)) {
                const notes = {};
                if (this.successIDVChanged && this.successIDV) {
                    notes.successIDV = this.successIDV;
                    if (this.emptyData || this.emptySuccessIDV) {
                        delete notes.successIDV.id;
                    }
                }
                if (this.disabledIDVChanged && this.disabledIDV) {
                    notes.disabledIDV = this.disabledIDV;
                    if (this.emptyData || this.emptyDisabledIDV) {
                        delete notes.disabledIDV.id;
                    }
                }
                if (this.skippedIDVChanged && this.skippedIDV) {
                    notes.skippedIDV = this.skippedIDV;
                    if (this.emptyData || this.emptySkippedIDV) {
                        delete notes.skippedIDV.id;
                    }
                }
                return Object.keys(notes).length > 0 ? notes : null;
            }

        },
        loadData() {
            this.config.email_subject = this.currentItem?.data?.template?.subject
            this.content = this.currentItem?.data?.template.body ? this.currentItem?.data?.template.body : this.currentItem?.data?.template.content
            this.mail = this.currentItem?.data?.template.body ? this.currentItem?.data?.template.body : this.currentItem?.data?.template.content
            this.smsMessage = this.currentItem?.data?.template?.smsContent
            if (this.smsMessage) this.sms = this.smsMessage


            if (this.currentItem?.duplicate == true) {
                this.config.name = ""
            }
            else {
                this.config.name = this.currentItem?.item?.name
            }
            this.config.category = this.currentItem?.item?.category
            this.config.type = this.currentItem?.item?.type

            // property selection form in property settings
            if (this.currentItem?.propertyId) {
                const property = this.facilityList.find(item => item.id === this.currentItem?.propertyId);
                this.config.properties.push(property);
            }
            // property selection form in corporate settings
            else {
                // property selection form in corporate settings if it is base template
                if (this.currentItem?.item?.is_base_template === 1) {
                    this.facilityList.forEach(propertyId => {
                        this.config.properties.push(propertyId);
                    })
                }
                // property selection form in corporate settings
                const propertyIds = this.currentItem?.data?.property_ids; // getting from db
                propertyIds?.forEach(propertyId => {
                    // Find the property with the matching ID
                    const property = this.facilityList.find(item => item.id === propertyId);

                    // If property found, append its name to this.config.properties
                    if (property) {
                        this.config.properties.push(property);
                    } else {
                        // If property not found in facilityList, push its ID to config.properties
                        this.config.properties_backup.push({ id: propertyId });
                    }
                });
            }
        },
        getConfigData() {
            // Return the form data
            const { name, category, type, properties, properties_backup } = this.config;

            let data = {
                name,
                category,
                type,
                properties,
            };

            if (properties && properties.length > 0) {
                data.properties = properties.map(property => property.id);
            }

            if (properties_backup && properties_backup.length > 0) {
                if (!data.properties) {
                    data.properties = [];
                }
                data.properties = data.properties.concat(properties_backup.map(property => property.id));
            }
            return data;
        },
        previewSMS() {
            this.templateSettings = 1
            this.$emit('scrollIntoView', true);
            this.sms = this.smsMessage;
        },
        previewContent() {
            this.templateSettings = 1
            this.$emit('scrollIntoView', true);
            this.mail = this.content
        },
        addOneTimePaymentLink() {

        },
        handleItemClick(index) {

            if (index == 0) {
                this.content += `<button style="background: linear-gradient(180deg, #47C0BF -70%, #00848E 126.25%);">View Account</button>`;
            }
        },
        getData() {
            // Return the form data
            let data = {}
            if (this.config?.type === 'sms' || this.currentItem?.item?.type === 'sms') {
                data = {
                    smsContent: this.smsMessage,
                }
            }
            if (this.config?.type == 'email' || this.currentItem?.item?.type === 'email') {
                data = {
                    subject: this.config.email_subject,
                    body: this.content,
                }
            }
            return data;
        },

    },
}

</script>

<style scoped>
.left-panel {
    width: 50% !important;
    margin-bottom: 60px;
    padding-left: 23px;
}

.right-panel {
    width: 50% !important;
    margin-bottom: 60px;
}

/* .right-panel .right-preview {
    position: -webkit-sticky;
    position: sticky;
    top: 80px;
} */

/* .left-panel .left-preview {
    position: -webkit-sticky;
    position: sticky;
    top: 80px;
    z-index: 9999;
} */

.tm-content /deep/ p {
    margin: 0px;
}

.tm-content /deep/ p:empty {
    /* to show empty p tag */
    min-height: 1em;
}

.email-rich-editor {
    padding: 0 !important;
    border: 1px solid #DFE3E8;
    border-radius: 4px;
}

.content-wrapper-rich-text {
    margin-top: 12px;
    background: white;
    padding: 9px 7px 0px 7px;
    border: 1px solid rgb(235, 235, 235) !important;
}

.content-wrapper-rich-text .email-rich-editor> :first-child {
    /* padding: 10px !important;
    border-bottom: 1px solid rgb(235, 235, 235) !important; */
    /* this style not need due to have update the tiptop ui*/
    background-color: #F9FAFB !important;
}

.content-wrapper-rich-text .email-rich-editor> :nth-child(2) {
    border-bottom: 1px solid rgb(235, 235, 235) !important;
    border-top: 0px solid rgb(235, 235, 235) !important;
    border-right: 0px solid rgb(235, 235, 235) !important;
    border-left: 0px solid rgb(235, 235, 235) !important;
}

.content-wrapper-rich-text .email-rich-editor> :nth-child(2) :first-child {
    overflow-y: auto !important;
}

.bottom-action> :nth-child(3) {
    margin-left: 7px;
    margin-top: 5px;
}

/* .content-wrapper-rich-text .email-rich-editor> :first-child>>>span:nth-child(n+6) {
    margin-left: 12px !important;
} */

/* width */
.content-wrapper-rich-text .email-rich-editor> :nth-child(2) :first-child ::-webkit-scrollbar {
    width: 10px;
}

/* Track */
.content-wrapper-rich-text .email-rich-editor> :nth-child(2) :first-child ::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
.content-wrapper-rich-text .email-rich-editor> :nth-child(2) :first-child ::-webkit-scrollbar-thumb {
    background: #afabab;
    cursor: default;
}

/* Handle on hover */
.content-wrapper-rich-text .email-rich-editor> :nth-child(2) :first-child ::-webkit-scrollbar-thumb:hover {
    background: #838181;
}

.hb-bab-left-actions-wrapper>>>a:nth-of-type(1) {
    padding-left: 8px;
}

.hb-bab-right-actions-wrapper .hb-bab-right-actions-wrapper-span>>>button,
.hb-bab-right-actions-wrapper .hb-bab-right-actions-wrapper-span>>>a {
    margin-left: 12px;
}

.hb-bab-border-bottom-radius {
    border-bottom-left-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
}

.bg-white {
    background-color: #FFFFFF;
}
</style>
