import { render, staticRenderFns } from "./templateEdit.vue?vue&type=template&id=bb34f516&scoped=true&"
import script from "./templateEdit.vue?vue&type=script&lang=js&"
export * from "./templateEdit.vue?vue&type=script&lang=js&"
import style0 from "./templateEdit.vue?vue&type=style&index=0&id=bb34f516&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bb34f516",
  null
  
)

export default component.exports