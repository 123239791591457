<template>
    <div class="section-content ma-0 pa-0 edit-template-config">
        <v-expansion-panels v-model="panel" class="expansion-pannel-config mx-auto mb-3">
            <hb-expansion-panel :top-margin="false">
                <template v-slot:title>
                    Template Settings
                </template>
                <template v-slot:content>
                    <hb-form label="Template Name" description="How this template will be listed in template list" full
                        required>
                        <HbTextField v-model="config.name" v-validate="{ required: true }" data-vv-name="name"
                            data-vv-scope="general" data-vv-as="Template Name" :error="errors.has('general.name')"
                            placeholder="Template Name" class="mb-2" @blur="checkDuplicateName($event)" />
                    </hb-form>
                    <hb-form label="Template Type" description="Select the feature that this template will be used in" full
                        required>
                        <HbSelect v-model="config.category" :items="items" v-validate="{ required: true }"
                            data-vv-scope="general" data-vv-name="category" data-vv-as="Template Type"
                            :error="errors.has('general.category')" placeholder="Select Type" class="mb-3" />
                    </hb-form>
                    <hb-form label="Message Type" full required>
                        <HbSelect v-model="config.type" :items="itemsTypes" v-validate="{ required: true }"
                            data-vv-scope="general" data-vv-name="type" data-vv-as="Message Type" :disabled="!currentItem.isNew"
                            :error="errors.has('general.type')" placeholder="Select Message Type" class="mb-3" />
                    </hb-form>
                    <!-- <hb-form label="Enable Properties" full required>
                        <hb-combobox style="max-width: 720px !important;" v-model="config.properties"
                            v-validate="{ required: true }" editable :items="filterPropertyList" data-vv-name="property"
                            data-vv-as="properties" item-text="name" label="Select properties" :disabled="checkIfCreate"
                            :error="errors.has('general.property')" @click="removeProperty" />
                    </hb-form> -->
                </template>
            </hb-expansion-panel>
        </v-expansion-panels>
    </div>
</template>
<script type="text/babel">
import { mapGetters } from "vuex";
import api from '../../../../assets/api.js';
import { notificationMixin } from '../../../../mixins/notificationMixin.js';
import { Validator } from 'vee-validate'
export default {
    name: "template-config",
    expose: ['getData'],
    props: ['currentItem'],
    mixins: [notificationMixin],
    data() {
        return {
            panel: 0,
            itemsTypes: [
                { text: 'SMS', value: 'sms' },
                { text: 'Email', value: 'email' },
            ],
            items: [
                { text: 'Basic', value: 'basic' },
                { text: 'Alert', value: 'alert' },
                { text: 'Delinquency', value: 'delinquency' },
                { text: 'Welcome Email', value: 'welcome_email' },
                { text: 'Maintenance', value: 'maintenance' },
            ],
            config: {
                name: '',
                category: 'basic',
                type: '',
                properties: [],
            },
            validating: false
        };
    },
    components: {

    },
    computed: {
        ...mapGetters({
            facilityList: 'propertiesStore/filtered',
        }),
        filterPropertyList() {
            return this.facilityList.map(obj => ({ id: obj.id, name: obj.name }));
        },
        checkIfCreate() {
            if (!this.currentItem) {
                // If currentItem is not present(new create)
                return false;
            }
            else {
                // If this.currentItem exists, check if its propertyId is not an empty string
                if (!this.currentItem?.propertyId) {
                    // If propertyId is an empty string
                    return false
                } else {
                    // If propertyId is not an empty string
                    return true
                }
            }
        },
    },
    watch: {
        config: {
            handler(config) {
                // Emit the value to the parent component
                this.$emit('messageTypeChange', config);
            },
            deep: true
        },
    },
    mounted() {
        this.loaddata()
    },
    methods: {
        loaddata() {
            if (this.currentItem?.duplicate == true) {
                this.config.name = ""
            }
            else {
                this.config.name = this.currentItem?.item?.name
            }
            this.config.category = this.currentItem?.item?.category
            this.config.type = this.currentItem?.item?.type
            if (this.currentItem?.propertyId) {
                const property = this.facilityList.find(item => item.id === this.currentItem?.propertyId);
                this.config.properties.push(property);
            }
            else {
                const propertyIds = this.currentItem?.item?.propertyIds; // geting from db
                propertyIds?.forEach(propertyId => {
                    // Find the property with the matching ID
                    const property = this.facilityList.find(item => item.id === propertyId);

                    // If property found, append its name to this.config.properties
                    if (property) {
                        this.config.properties.push(property);
                    }else {
                        // If property not found in facilityList, push its ID to config.properties
                        this.config.properties.push({ id: propertyId });
                    }
                });
            }
        },
        getData() {
            // Return the form data
            const { name, category, type, properties } = this.config;

            let data = {
                name,
                category,
                type,
                properties
            };

            if (properties && properties.length > 0) {
                data.properties = properties.map(property => property.id);
            }
            return data;
        },
        async checkDuplicateName() {
            try {
                if (!this.currentItem?.edit == true && this.config.name) {
                    const res = await api.patch(this, api.TEMPLATE_MANAGER + `template/check` + `?templateName=${this.config.name}`);
                    if (res.isTemplateExists === true) {
                        this.showMessageNotification({ type: 'error', description: `Template with this name already exists` });
                        this.$emit('disable', true);
                    } else {
                        // If template with the same name doesn't exist, emit the event
                        this.$emit('disable', false);
                    }
                }
            } catch (error) {
                // Handle any errors that occur during the API call
                console.error('Error occurred:', error);
            }
        },
        removeProperty() {
            
        }
    }
}

</script>
<style scoped>
.edit-template-config {
    background-color: #F9FAFB;
}

.expansion-pannel-config {
    width: 80%;

}
</style>