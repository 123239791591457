<template>
    <div class="pr-5">

        <v-row no-gutters class="my-4" v-if="property_id && !isLoadingProducts && fees.length">
            <div class="hb-font-header-2-medium">Select Service Charges</div>
        </v-row>
        
        <async-loader v-if="isLoadingProducts" style="left: 27%;" />
        <v-container v-else-if="!isLoadingProducts && fees.length" class="pa-0 pb-2 position-relative pr-2 insurance-container" :class="{'scrollable-content scroll-products' : fees.length}">
        <!-- <v-container class="insurance-container"> -->
            <v-row class="merchandise-row mx-0">
                <v-col style="flex: 0 1 50px;">
                    <label>&nbsp;</label>
                </v-col>
                <v-col style="flex: 2">
                    <label style="padding-left:6px;">Name</label>
                </v-col>

                <v-col class="text-right" style="flex: 0 1 100px">
                    <label>Price</label>
                </v-col>
            </v-row>

            <div v-for="(product, index) in fees" :key="product.id">
                <v-row class="merchandise-row mx-0" v-if="!quick_action || (quick_action && product.amount_type !== 'percentage' && product.amount_type !== 'scheduled')">
                    <v-col style="flex: 0 1 50px;">
                        <hb-checkbox @change="saveService(product)" v-model="product.qty"></hb-checkbox>
                    </v-col>
                    <v-col style="flex: 2">
                        <strong>{{product.name}}</strong><br />
                        <p>{{product.description}}</p>
                    </v-col>
    <!--                <v-col style="flex: 1 0 60px">-->
    <!--                    <span v-if="product.qty">-->
    <!--                    </span>-->
    <!--                </v-col>-->
                    <v-col class="text-right" style="flex: 0 1 100px">
                        <v-text-field v-if="isVariableRate(product.amount_type) || isFeeSchedule(product.amount_type)"
                            dense
                            name="price"
                            v-model.number="product.price"
                            @change="saveService(product, true)"
                            label="Price"
                            v-validate="'decimal:2|max_value:9999'"
                            data-vv-name="price"
                            :id="'price_' + product.id"
                            :name="'price_' + product.id"
                            outlined>
                        </v-text-field>
                        <span v-else-if="product.activated && isPercentage(product.amount_type)">{{product.service_price | formatPercentage }}</span>
                        <span v-else>{{product.price | formatMoney}}</span>
                    </v-col>
    <!--                <v-col class="text-right" style="flex: 0 1 120px">-->
    <!--                    <span v-show="product.activated">-->
    <!--                        <v-btn @click="saveService(product)" v-if="product.Service.id" class="secondary">Update</v-btn>-->
    <!--                        <small @click="removeService(product)" v-if="product.Service.id">Remove</small>-->
    <!--                        <v-btn @click="saveService(product)" v-if="!product.Service.id" class="primary">Add</v-btn>-->
    <!--                    </span>-->
    <!--                </v-col>-->
                </v-row>
            </div>

        </v-container>

        <hb-empty-state class="mt-5" v-if="!isLoadingProducts && property_id && !fees.length"
            message="No record available against this property">
        </hb-empty-state>
    </div>
</template>

<script type="text/babel">

    import api from '../../../assets/api.js';
    import HbDatePicker from '../../assets/HummingbirdDatepicker';
    import moment from 'moment';
    import { EventBus } from '../../../EventBus.js';
	import { notificationMixin } from  '../../../mixins/notificationMixin.js';
    import AsyncLoader from '../../includes/Loading.vue';

    export default {
        name: "ConfigureMerchandise",
		mixins: [ notificationMixin ],
        data() {
            return {
                products:[],
                product_search: '',
            }
        },
        components:{
            HbDatePicker,
            AsyncLoader
        },

        created (){

        },
        props: ['fees', 'services', 'lease_id', 'property_id', 'isLoadingProducts', 'quick_action'],
        filters:{
            lineTotal(c){
                return c.qty * c.cost;
            },
            getEndOfPeriod(date){
                return moment(date).add(1, 'month').subtract(1, 'day');
            }
        },
        computed: {

            lease_services(){
                return this.$store.getters.leaseServices;
            },


        },
        methods: {
            async saveService(product, isFromTextField = false){
                
                let status =  await this.validate(this);
                if(!product.price){
                    this.showMessageNotification({ type: 'error', description: 'Please enter a valid Price.' });
                    return;   
                };
                if(!status) return;
                if(product.price && parseFloat(product.price) < 0.01) {
                    this.showMessageNotification({ type: 'error', description: 'The price field must be 0.01 or more' });
                    return;
                }

                if(!product.qty && !isFromTextField){
                    this.removeService(product);
                    return;
                }

                var data = {
                    lease_id: this.lease_id,
                    product_id: product.id,
                    name: product.name,
                    price: product.price,
                    type: product.type,
                    qty: 1,
                    description: product.description,
                };

                this.$set(this.services, product.id, data);
                await this.$emit('calculateTotal');

            },
            removeService(product){
                product.qty = null;
                this.services[product.id] = {};
                this.$emit('services', this.services);  
            },
            isVariableRate(rate_type) {
                return rate_type === 'variable';
            }, 
            isPercentage(rate_type) {
                return rate_type === 'percentage';
            },
            isFeeSchedule(rate_type) {
                return rate_type === 'scheduled';
            }
        },

    }
</script>

<style scoped>

    .insurance-container{
        border: 1px solid #DFE3E8;
        background: white;
        border-radius: 4px;
        padding: 8px 36px;
    }

    .insurance-container .v-input--radio-group--column{
        margin: 0;
    }
    .insurance-container .insurance-row {
        margin-bottom: 10px;

    }
    .insurance-option-row.no-border{
        border-bottom: none;
    }
    .merchandise-row{
        border-bottom: 1px solid #DFE3E8;
    }
    .merchandise-row:last-child{
        border-bottom: none;
    }


    .insurance-container .v-input--selection-controls.v-input--checkbox {
        margin-top: 0;
    }

    .scroll-products {
        height: calc(100vh - 450px);
    }



</style>
