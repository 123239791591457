<template>
    <div class="lead-intake-container">
        <hb-blade-header title="National Account - Add Spaces" backButtonOff @close="CloseNationalAccount">
        </hb-blade-header>
        <div class="lead-intake-top" no gutters>
            <!-- left side -->
            <div class="movein-left">
                <div class="pa-4">
                    <hb-mini-profile type="account" :title="contact.Business.name" :status="contact.status ? contact.status : undefined
                        " :address="computedBusinessAddress" :id-verified="contact.email_verified
                            ? contact.email_verified
                            : undefined
                            " :account-space-count="calculateLength"
                        :account-space-type="contact.Leases && contact.Leases?.length === 1 ? contact?.Leases[0]?.Unit?.type : ''"
                        :account-size="NationalAccount.total_sqm + ' sqm'"
                        :account-total-monthly-spent="NationalAccount.total_monthly_rent | formatMoney" />
                    <v-divider class="my-0 pa-0 mt-3"></v-divider>
                    <v-expansion-panels v-model="panel" multiple class="mt-4">
                        <hb-expansion-panel :left-cols="6" :right-cols="5" disable-title-icon :top-margin="false">
                            <template v-slot:title>Account Information </template>
                            <template v-slot:actions>
                                <v-row no-gutters class="pl-2">
                                    <v-col cols="8" class="pa-0 ma-0 d-flex justify-end hb-font-header-3-medium">
                                    </v-col>
                                </v-row>
                            </template>
                            <template v-slot:content>
                                <div>
                                    <hb-form label="National Account" :auto-layout="false">
                                        <template>
                                            {{ formdata.name }}
                                            <v-row no-gutters>
                                                <v-col v-if="formdata.address.address">
                                                    {{ formdata.address.address }} {{ formdata.address.city }}
                                                    <span v-if="formdata.address.state"> , </span>
                                                    {{ formdata.address.state }} {{ formdata.address.zip }}
                                                </v-col>
                                            </v-row>
                                        </template>
                                    </hb-form>

                                    <hb-form label="Primary Contact" full :auto-layout="false">
                                        <template>
                                            <v-row no-gutters>
                                                <v-col cols="12">
                                                    {{ formdata.contact.first }} {{ formdata.contact.last }}
                                                </v-col>
                                                <v-col cols="12">
                                                    {{ formdata.contact.email }}
                                                </v-col>
                                                <v-col cols="12">
                                                    {{ formdata.contact.Phones[0].type }} :
                                                    {{ formdata.contact.Phones[0].phone | formatPhone }}
                                                </v-col>
                                            </v-row>
                                        </template>
                                    </hb-form>
                                    <hb-form label="Department"
                                        description="Select a department to associate it with the unit.">
                                        <template>
                                            <HbSelect v-model="department" :items="formdata.departments"
                                                v-validate="'required|max:45'" data-vv-scope=""
                                                data-vv-name="department" data-vv-as="Department" item-text="name"
                                                item-value="id" :error="errors.has('nationalaccount.department')"
                                                placeholder="Select department" :clearable="false" />
                                        </template>
                                    </hb-form>
                                    <hb-form label="Notes" full>
                                        <template>
                                            <HbTextarea :rows=6 row-height="15" v-model="notes"
                                                data-vv-scope="nationalaccount" data-vv-name="notes" data-vv-as="Notes"
                                                :error="errors.has('nationalaccount.message')" placeholder="Enter Notes"
                                                placeholder-fix />
                                        </template>
                                    </hb-form>
                                </div>
                            </template>
                        </hb-expansion-panel>
                    </v-expansion-panels>
                </div>
            </div>
            <!-- <v-col cols="1">
            <v-divider vertical></v-divider>
           </v-col> -->
            <div class="movein-right">
                <!-- right side -->
                <hb-tabs v-model="activeTab" :background-color="'#f9fafb'" height="72px" style="margin-bottom: 30px;">
                    <v-tab :ripple="false" @click="activeTab = 0" :disabled="grayOutSpace"> Spaces </v-tab>
                    <v-tab-item class="tab-items">
                        <unit v-if="!configuration_unit || !configuration_unit.id" ref="unitsComponentRef"
                            :unit_id="unit_id" @setUnit="setUnit" :propertyId="property_id" :isNational=isNational></unit>
                        <div class="lease-info-main">
                            <lease-info v-if="configuration_unit && configuration_unit.id && isNational"
                                :unitComponentStatusFlag="true" ref="leaseInfoComponentReference" @goBack="unsetUnit"
                                @saved="leaseConfigured" :isNational=isNational :promotion_na="promotion" :contact_na ="this.formdata.contact.id"></lease-info>
                        </div>
                    </v-tab-item>

                    <v-tab :ripple="false" @click="activeTab = 1" v-show="showService">
                        Services </v-tab>
                    <v-tab-item class="tab-items"
                        :class="{ 'add-padding-overlay-active': configuration_unit && configuration_unit.id }">
                        <services></services>
                    </v-tab-item>

                    <v-tab :ripple="false" @click="activeTab = 2" v-if="contact?.id">
                        Communication
                    </v-tab>
                    <v-tab-item class="tab-items">
                        <ChatWindow dense chatWindowHeight="large" noHeader :InteractionObj="contact"
                             v-if="contact?.id"  from="national_account"
                            context="'national_account'"  :currentPropertyId="property_id !== 'all-property' ? property_id : null"></ChatWindow>
                    </v-tab-item>
                </hb-tabs>
                <lease-total-overlay v-if="configuration_unit && configuration_unit.id && (activeTab === 1)"
                    class="lease-total-overlay"></lease-total-overlay>
            </div>
        </div>
        <hb-bottom-action-bar @close="CloseNationalAccount" :cancel-off="movinButtonLoading">
            <template v-slot:right-actions>
                <hb-btn color="primary" @click="saveLease" v-if="!showFinal" :disabled="movinButtonDisable"
                    :loading="movinButtonLoading">Move-In</hb-btn>
                <hb-btn color="primary" @click="final" :loading="finalButtonLoading" v-if="showFinal">Finalize</hb-btn>
            </template>
        </hb-bottom-action-bar>
        <hb-modal v-model="close_confirm" size="medium" title="Incomplete Lease Set Up" confirmation show-help-link>
            <template v-slot:content>
                <div class="py-6 px-6">
                    This lease has not been completed. Would you like to delete it?
                </div>
            </template>
            <template v-slot:actions>
                <hb-btn color="destructive" @click="deleteLease" :loading="deleteLeaseLoading">Delete This Lease</hb-btn>
            </template>
        </hb-modal>
    </div>
</template>
<script>
import api from "../../../../assets/api.js";
import Unit from "../../../includes/LeadIntake/Unit.vue";
import LeaseInfo from "../../../includes/LeadIntake/LeaseInfo.vue";
import services from "../../../includes/LeadIntake/Services.vue";
import ChatWindow from "../../../communication_center/ChatWindow.vue";
import LeaseTotalOverlay from "../../../includes/LeadIntake/LeaseTotalOverlay.vue";
import { notificationMixin } from "@/mixins/notificationMixin.js";
import { mapGetters, mapMutations } from "vuex";
import { EventBus } from "../../../../EventBus.js";
export default {
    name: "MoveIn",
    props: ['contact', 'unit_id', 'property_id'],
    mixins: [notificationMixin],
    data() {
        return {
            deleteLeaseLoading: false,
            gps_payload: {},
            close_confirm: false,
            grayOutSpace: false,
            showFinal: false,
            isNational: true,
            movinButtonDisable: true,
            finalButtonLoading: false,
            movinButtonLoading: false,
            showService: false,
            panel: [0],
            activeTab: 0,
            interactionContext: 'moveIn',
            discount_id: '',
            promotion: '',
            formdata: {
                name: "",
                taxId: "",
                address: {
                    id: "",
                    country: "",
                    address: "",
                    city: "",
                    state: "",
                    zip: "",
                },
                contact: {
                    id: "",
                    first: "",
                    last: "",
                    email: "",
                    Phones: [
                        {
                            id: "",
                            phone: "",
                            type: "",
                            sms: false,
                        },
                    ],
                },
                promotion_id: '',
                departments: [],
                next_review_date: "",
            },
            department: '',
            notes: '',
            form: {
            },
            // noteCatagories: '',
        };
    },
    components: {
        Unit,
        services,
        ChatWindow,
        LeaseInfo,
        LeaseTotalOverlay
    },
    created() {
        EventBus.$on('reset_lease_hb_model', this.openModel);
    },
    async mounted() {
        await this.setAccountInfo()
        // let selectedDepartment = this.formdata.departments.find(department => department.default_department == 1)
        // this.department = selectedDepartment.id
    },
    computed: {
        ...mapGetters({
            properties: "propertiesStore/filtered",
            configuration_unit: 'onBoardingStore/configuration_unit',
            unit: 'onBoardingStore/unit',
            configuration_lease: 'onBoardingStore/configuration_lease',
            lease: 'onBoardingStore/lease',
            insurance: 'onBoardingStore/insurance',
            NationalAccount: 'onBoardingStore/getNationalAccount'
        }),
        calculateLength() {
            let leases = this.contact?.Leases.filter(lease => !lease.end_date)
            return leases?.filter((lease) => {
                return this.filterLeases(lease.Unit.property_id);
            })?.length;
        },
        computedBusinessAddress() {
            if (
                this.contact.Business &&
                this.contact.Business.Address
            ) {
                const address_1 = this.contact.Business.Address.address
                    ? this.contact.Business.Address.address
                    : "";
                const address_2 = this.contact.Business.Address.address2
                    ? this.contact.Business.Address.address2
                    : "";

                let address = address_1;
                if (address_2 != "") address += " " + address_2;

                address +=
                    ", " +
                    this.$options.filters.formatAddress(
                        this.contact.Business.Address
                    );

                return address;
            } else {
                return undefined;
            }
        },
        canProgress() {
            if (this.lease.decline_insurance || this.insurance.id) {
                return true;
            }
            return false;
        },
    },
    destroyed() {
        this.unsetUnit();
        EventBus.$off('reset_lease_hb_model', this.openModel);
    },
    beforeDestroy() {
        this.$store.commit("onBoardingStore/resetTenants");
        this.$store.commit("onBoardingStore/resetLease");
        this.$store.commit("onBoardingStore/resetInvoice");
        this.$store.commit("onBoardingStore/resetReservation");
        this.$store.commit("onBoardingStore/resetContact");
        this.$store.commit("onBoardingStore/resetUnit");
        this.$store.commit("onBoardingStore/resetProperty");
        this.$store.commit("onBoardingStore/resetConfigurationUnit");
        this.$store.commit("onBoardingStore/resetInitialCharges");
        this.$emit("close");
    },
    methods: {
        ...mapMutations({
            setContact: 'onBoardingStore/setContact',
            setLeaseProperty: 'onBoardingStore/setLeaseProperty',
            setRefreshTenantProfile: 'tenantProfile/setRefreshTenantProfile',
        }),
        openModel(payload = {}) {
            this.gps_payload = payload;
            if ('property_ids' in this.gps_payload && this.gps_payload.property_ids.length > 0) EventBus.$emit('reset_gps_bulk_property', this.gps_payload);
            else EventBus.$emit('reset_gps_property', this.gps_payload);
        },
        CloseNationalAccount() {
            if (this.showFinal) {
                this.close_confirm = true
            }
            else {
                this.$emit('close')
            }
        },
        filterLeases(property_id) {
            return this.properties.some((i) => i.id === property_id);
        },
        async deleteLease() {
            if (!this.lease.id) return;
            EventBus.$emit('unit_available_changed');
            try {
                this.deleteLeaseLoading = true
                await this.$store.dispatch('onBoardingStore/deletePending');
                this.$emit('close');
                // if we change property from GPS while move in, we need to change property after delete.
                if ('property_ids' in this.gps_payload && this.gps_payload.property_ids.length > 0) EventBus.$emit('reset_gps_bulk_property', this.gps_payload);
                else EventBus.$emit('reset_gps_property', this.gps_payload);
                this.deleteLeaseLoading = false
            } catch (err) {
                this.showMessageNotification({ description: `Lease deletion error: ${err}` });
                let currentLease = await api.get(this, api.LEASES + this.lease.id + '/status');
                if (currentLease.lease.status === 1) {
                    this.$emit('close');
                }
                this.deleteLeaseLoading = false
            }
        },
        async saveLease() {
            try {
                this.movinButtonLoading = true
                this.$refs.leaseInfoComponentReference.saveLease().then( async(res) => {
                    if (res == 'LEASE_FORM_ERROR') {
                        this.movinButtonLoading = false
                    } else {
                        this.movinButtonLoading = false
                        this.showService = true // show service tab
                        this.activeTab = 1 // change tag to service 
                        this.showFinal = true // show final button
                        this.grayOutSpace = true // disable space tab
                        await this.$store.dispatch("onBoardingStore/getServices"); // call service
                    }
                })
            } catch (error) {
                console.log(error);
                this.movinButtonLoading = false
            } finally {
            }
        },
        async final() {
            try {
                let general_status = await this.$validator.validateAll('nationalaccount');
                if (!general_status) {
                    this.showMessageNotification();
                    return;
                }
                if (!this.canProgress) {
                    this.showMessageNotification({
                        type: 'error',
                        title: 'Please select a coverage.',
                        description: 'You need to select a coverage option to proceed to payments.',
                    });
                    return
                }
                this.finalButtonLoading = true
                this.setLeaseProperty({ property: 'billed_months', propertyValue: 0 });
                await this.$store.dispatch('onBoardingStore/generateInvoice')
                let data = {
                    "billed_months": 0, "payment": {}, "threshold": false
                }
                await api.post(this, api.LEASES + this.lease.id + '/finalize', data)
                if (this.department) {
                    await api.post(this, api.LEASE_DEPARTMENT, {
                        "department_id": this.department,
                        "lease_id": this.lease.id
                    })
                }
                // only if notes is there
                if (this.notes) {
                    var noteData = {
                        content: this.notes,
                        // notes_category_id: TODO TODO TODO TODO TODO TODO,
                        property_id: this.configuration_unit.property_id,
                        unit_number: this.configuration_unit.number,
                    }
                    await api.post(this, api.CONTACTS + this.contact.id + '/notes', noteData)
                }
                this.finalButtonLoading = false
                this.setRefreshTenantProfile(true);
                this.$emit('close');
            } catch (error) {
                console.log(error);
                this.finalButtonLoading = false
            }
        },
        async leaseConfigured(lease) {
            this.loading = true;
            await this.$store.dispatch("onBoardingStore/getServices");
            // this.$store.commit('onBoardingStore/resetConfigurationUnit');
            this.$store.commit("onBoardingStore/resetInitialCharges");
            // this.step = 3;
            await this.$store.dispatch("onBoardingStore/getEstimate");
        },
        async setUnit(data) {
            console.log("set unit id", data);
            this.loading = true;
            this.$store.commit("onBoardingStore/resetConfigurationLease");
            this.$store.commit("onBoardingStore/resetConfigurationInvoice");
            await this.$store.dispatch("onBoardingStore/getConfigurationUnitInfo", {
                unit_id: data.unit_id,
            });
            // calling to update contact details in store
            await this.$store.dispatch('onBoardingStore/getContactInfo', { contact_id: this.contact.id, force: true });
            this.loading = false;
            this.movinButtonDisable = false
        },
        unsetUnit() {
            this.loading = false;
            this.$store.commit("onBoardingStore/resetConfigurationUnit");
            // this.$store.commit('onBoardingStore/resetLease');
            this.$store.commit("onBoardingStore/resetInitialCharges");
            // this.summary_panel = "property";
        },
        // async getNoteCatagories(property_id) {
        //     let catagories = await api.get(this, api.NOTES_CATEGORIES + '?property_id=' + property_id)
        //     this.noteCategories = catagories.notes_catagories
        //         .filter(item => item.id === cat_id)
        //         .map(item => item.notes_category);
        // },
        async setAccountInfo() {
            let data;
            try {
                await api
                    .get(
                        this,
                        api.NATIONAL_ACCOUNT +
                        `${this.$route.query.na_id}?contact_id=${this.$route.params.contact_id}`
                    )
                    .then(async (res) => {
                        data = res[0];
                    });

                this.formdata.name = data.name;
                this.formdata.departments = data.departments;
                this.discount_id = data.promotion_id;
                this.promotion = data.promotion;

                this.formdata.address.id = this.contact.Business.Address.id;
                this.formdata.address.address = this.contact.Business.Address.address;

                this.formdata.contact.id = this.contact.id;
                this.formdata.contact.first = this.contact.first;
                this.formdata.contact.last = this.contact.last;
                this.formdata.contact.email = this.contact.email; // this.setStatesListEvent(this.contact.Business.Address.country, 0);

                this.formdata.address.city = this.contact.Business.Address.city;
                this.formdata.address.state = this.contact.Business.Address.state;
                this.formdata.address.zip = this.contact.Business.Address.zip;

                this.formdata.contact.Phones = this.contact.Phones;
                try {
                    var parseEditedPhone = parsePhoneNumber(
                        "+" + this.contact?.Phones[0].phone
                    );
                } catch (err) { }
                if (!parseEditedPhone) {
                    this.phoneCode = "";
                    this.phoneNumber = this.form.Phones[i].phone;
                } else {
                    this.phoneCode = parseEditedPhone.countryCallingCode;
                    this.Phone = parseEditedPhone.nationalNumber;
                }


            } catch (error) {
                console.log(error, "error");
            }
        },
    },
}
</script>
<style scoped>
.lead-intake-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    background-color: #f9fafb;
}

.lead-intake-top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    flex-grow: 1;
    overflow: auto;
}

.movein-left {
    align-items: stretch;
    display: flex;
    flex-direction: column;
    flex: 5;
    max-width: 780px;
    min-width: 390px;
    overflow: hidden;
    height: 100%;
    box-shadow: 8px 0px 18px rgba(71, 84, 116, 0.2) !important;
    z-index: 202 !important;
}

.movein-right {
    flex: 4;
    display: flex;
    overflow: hidden;
}

.movein-right>>>.v-window {
    height: calc(100% - 20px) !important;
}

.add-padding-overlay-active {
    padding-bottom: 150px !important;
}

.tab-items {
    /* display: flex; */
    flex: 1;
    overflow: auto;
    height: 100%;
    padding-bottom: 5%;
    background-color: #f9fafb !important;
    /* width: -webkit-fill-available; */
}

.footer-wrapper {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #ffffff;
    padding: 12px 16px;
    box-shadow: inset 0px 1px 0px rgba(142, 142, 143, 0.15);
    width: 100%;
    border-top: 1px solid #dfe3e8;
    z-index: 1;
}

.theme--light.v-tabs-items {
    background-color: #f9fafb !important;
}

.lease-info-main {
    overflow: hidden !important;
}

.lease-total-overlay {
    position: absolute;
    bottom: 60px;
    width: -webkit-fill-available;
    width: -moz-available;
    padding: 0;
    z-index: 300;
    box-shadow: 0px -4px 10px 3px rgba(71, 84, 116, 0.1);

}

.lease-total-overlay>>>.v-card {
    border-radius: 0;
    padding: 0px !important;
}
</style>