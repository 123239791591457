<template>
  <div class="hb-text-night">

    <status @resetStatus="errorClear($options.name)" v-if="errorHas($options.name)" :message="errorGet($options.name)" status="error"></status>
      <template v-if="table_rate_insurance">
        <div class="pa-4 hb-text-light">Select coverage type and enter amount desired.</div>
        <v-divider></v-divider>
      </template>
      <hb-radio-group 
        v-model="insurance_form" 
        @change="selectInsurance"
        :disabled="isLoading($options.name)"
        hide-details
        class="mt-4 pa-4"
       >
          <div v-if="!table_rate_insurance" v-for="(o, i) in optionalInsurance" :key="o.id" class="my-1">
            <hb-radio
              :value="o"
              :name="o.id"
              :id="o.id"
              :checked="insurance_form && o.id === insurance_form.id"
            >
              <template v-slot:label>
                <v-row no-gutters>
                  <v-col cols="12" class="hb-font-body">{{o.name}}</v-col>
                  <!-- <v-col cols="12" class="hb-text-night-light">{{o.description}}</v-col> -->
                </v-row>
              </template>
            </hb-radio>
            <!-- <v-divider v-show="i < optionalInsurance.length - 1" class="mt-3"></v-divider> -->
          </div>
          <div v-if="!optionalInsurance.length" class="hb-font-body">
            No Coverage Options Found
          </div>

          <div v-if="table_rate_insurance" class="pt-0">
            <table-rate-insurance
              :coverageAmount="coverage_value"
              :insurances="optionalInsurance"
              :isSelected="!!insurance_form"
              @updateCoverageValue="updateCoverageValue"
            />
          </div>

          <hb-radio
            :value="0"
            name="decline"
            id="decline"
            class="my-1"
          >
            <template v-slot:label>
              <v-row no-gutters>
                <v-col cols="12" class="hb-font-body">Tenant has their own coverage</v-col>
              </v-row>
            </template>
          </hb-radio>

          <div v-if="insurance_form === 0" class="px-8">
            <div class="hb-font-body hb-text-night-light">Please select the expiration date of the coverage policy</div>

            <v-row no-gutters class="mt-2" v-if="insurance_form === 0">
              <v-col cols="12" sm="6" class="ma-0 pa-0">
                <hb-select
                  v-model="insurance_exp_month"
                  :items="monthList"
                  placeholder="Month"
                  data-vv-name="insurance_exp_month"
                  data-vv-as="Expiration Month"
                  :error="errors.collect('insurance_exp_month').length > 0"
                  @change="selectInsurance"
                ></hb-select>
              </v-col>
              <v-col cols="12" sm="6" class="ma-0 pa-0" :class="{'pl-3' : $vuetify.breakpoint.smAndUp, 'mt-3' : $vuetify.breakpoint.xs}">
                <hb-select
                  v-model="insurance_exp_year"
                  :items="yearList"
                  placeholder="Year"
                  clearable
                  data-vv-name="insurance_exp_year"
                  data-vv-as="Expiration Year"
                  :error="errors.collect('insurance_exp_year').length > 0"
                  @change="selectInsurance"
                ></hb-select>
              </v-col>
            </v-row>
          </div>
      </hb-radio-group>

            
    <!-- <v-col  clas="align-right">
          <v-btn v-if="!policyUploaded" @click.stop="showAdd = true" text color="primary" style="font-weight:normal;">Snap or upload policy</v-btn>
          <v-row v-if="policyUploaded">
              <v-icon medium color="primary" class="mr-1">mdi-check</v-icon>
              <span style="color:#00848e;">Policy Uploaded</span>
          </v-row>
        </v-col> -->


<!--            <v-row class="hb-table-row">-->
<!--              <v-col cols="3" style="background-color: #F4F6F8;">-->
<!--                <v-container>-->
<!--                  <v-row no-gutters>-->
<!--                    <v-col><h2 class="subtitle-2 font-weight-medium">Insurance Brochure</h2></v-col>-->
<!--                  </v-row>-->
<!--                </v-container>-->
<!--              </v-col>-->
<!--              <v-col cols="9">-->
<!--                <v-container>-->
<!--                  <div>-->
<!--                    <v-row no-gutters>-->
<!--                      <v-col class="row-checkbox-col">-->
<!--                        <v-checkbox class="ma-0 pa-0" v-model="brochure" value="email" name="email_brochure" id="email_brochure"></v-checkbox>-->
<!--                      </v-col>-->
<!--                      <v-col >-->
<!--                        <label for="email_brochure"><strong>Email Brochure</strong></label>-->
<!--                      </v-col>-->
<!--                    </v-row>-->
<!--                    <v-row no-gutters>-->
<!--                      <v-col class="row-checkbox-col">-->
<!--                        <v-checkbox class="ma-0 pa-0" v-model="brochure" value="print" name="print_brochure" id="print_brochure"></v-checkbox>-->
<!--                      </v-col>-->
<!--                      <v-col >-->
<!--                        <label for="print_brochure"><strong>Print Brochure</strong></label>-->
<!--                      </v-col>-->
<!--                    </v-row>-->
<!--                  </div>-->
<!--                </v-container>-->
<!--              </v-col>-->
<!--            </v-row>-->
<!-- 
          </v-container>
        </v-radio-group>
      </v-card-text>
    </v-card> -->

    <upload-file
      v-model="showAdd"
      v-if="showAdd"
      type="file"
      model="leases"
      :foreign_id="lease.id"
      size="lg"
      slot="body"
      @save="policyUploaded = true"
    >
    </upload-file>
 
  </div>
</template>

<script type="text/babel">

    import api from '../../../assets/api.js';
    import Status from '../../includes/Messages.vue';
    import Loader from '../../assets/CircleSpinner.vue';
    import Modal from '../../assets/Modal.vue';
    import moment from 'moment';
    import UploadFile from '../../assets/UploadFile.vue';
    import { mapGetters } from 'vuex';
    import { EventBus } from '../../../EventBus.js';
    import TableRateInsurance from '../../table_rate_insurance/Index.vue';


    export default {
        name: "ConfigureInsurances",
        data() {
            return {
                services:[],
                taxRate: 0,
                showAddService:false,
                showDeleteService:false,
                showAddProduct:false,
                selectedService: null,
                offset: 0,
                service_updated: false,
                email_brochure: false,
                email_brochure_to: '',
                text_brochure: false,
                text_brochure_to: '',
                print_brochure: '',
                brochure: '',
                showAdd: false,
                policyUploaded: false,
                insurance_form: {},
                monthList:[
                  { text:'January', value:1 },
                  { text:'February', value:2 },
                  { text:'March', value:3 },
                  { text:'April', value:4 },
                  { text:'May', value:5 },
                  { text:'June', value:6 },
                  { text:'July', value:7 },
                  { text:'August', value:8 },
                  { text:'September', value:9 },
                  { text:'October', value:10 },
                  { text:'November', value:11 },
                  { text:'December', value:12 },
                ],
                yearList: [
                  { text: '2020', value: '2020'},
                  { text: '2021', value: '2021'},
                  { text: '2022', value: '2022'},
                  { text: '2023', value: '2023'},
                  { text: '2024', value: '2024'},
                  { text: '2025', value: '2025'},
                  { text: '2026', value: '2026'},
                  { text: '2027', value: '2027'},
                  { text: '2028', value: '2028'},
                  { text: '2029', value: '2029'},
                  { text: '2030', value: '2030'},
                ],
                insurance_exp_month: '',
                insurance_exp_year: '',
                optionalInsurance: [],
                table_rate_insurance: false,
                coverage_value: '',
            }
        },

        components:{
            Status,
            Modal,
            UploadFile,
            Loader,
            TableRateInsurance,
        },
        async created (){
            await this.fetchPropertyInsurances();
            this.setInsuranceForm();
            //this.selectInsurance = _.debounce(this.selectInsurance, 1000);
        },
        filters:{
            lineTotal(c){
                return c.qty * c.cost;
            },
            getEndOfPeriod(date){
                return moment(date).add(1, 'month').subtract(1, 'day');
            }
        },
        computed: {
            ...mapGetters({
                property: 'onBoardingStore/property',
                lease: 'onBoardingStore/lease',
                tenants: 'onBoardingStore/tenants',
                insurance: 'onBoardingStore/insurance',
                template: 'onBoardingStore/template',
                unit: 'onBoardingStore/unit'
            }),
        },
        methods: {
            setInsuranceForm(){
              let decline_insurance = false;
              if(this.lease.decline_insurance){
                decline_insurance = typeof this.lease.decline_insurance === 'object' ? this.lease.decline_insurance.decline_insurance : this.lease.decline_insurance;
              }
              this.table_rate_insurance = this.optionalInsurance.length && this.optionalInsurance[0]?.insurance_type === "table_rate" ? true : false;

              if(decline_insurance){
                  this.insurance_form = 0;
                  this.insurance_exp_month = this.lease.insurance_exp_month
                  this.insurance_exp_year =  this.lease.insurance_exp_year
              } else {
                this.insurance_form = this.optionalInsurance.find(i => i.product_id === this.insurance.product_id );
                if(this.table_rate_insurance) this.coverage_value = this.insurance?.coverage;
              }
            },
            async fetchPropertyInsurances() {
              try{
                const r = await api.get(this, api.PROPERTIES + this.property.id + '/insurances', { unit_type_id: this.unit?.unit_type_id });
                this.optionalInsurance = r.insurances;
              }catch(err){
                // do nothing
              }
            },
            insurancePrice(insurance){
                var premium;
                if(insurance.Insurance.premium_type === "%"){
                    premium = this.lease.rent * (insurance.Insurance.premium_value / 100);
                } else {
                    premium = insurance.Insurance.premium_value;
                }
                return premium; //(Math.round(premium * 1e2) / 1e2).toFixed(2);
            },
            async selectInsurance(o){
              try {
                this.startLoading(this.$options.name);
                if(this.insurance_form === 0){
                    let status = await this.validate(this);
                    if (!status) return
                    let data = {
                      insurance_exp_month: this.insurance_exp_month,
                      insurance_exp_year: this.insurance_exp_year,
                      decline_insurance: true
                    }
                    await this.$store.dispatch('onBoardingStore/declineInsurance', data);
                    await this.$store.dispatch('onBoardingStore/deleteInsurance', null);
                    EventBus.$emit('updatePaymentDetails');
                    EventBus.$emit('updatePartialPaymentAmount');
                    this.stopLoading(this.$options.name)
                    return;
                }
                const tableRateWithCoverageValue = this.table_rate_insurance && this.coverage_value;
                const libraryWithCoverageSelected = !this.table_rate_insurance && this.insurance_form && this.insurance_form.product_id !== this.insurance.product_id;
                if(tableRateWithCoverageValue || libraryWithCoverageSelected){
                    await this.saveInsurance(this.insurance_form);
                    let data = {
                      insurance_exp_month: null,
                      insurance_exp_year: null,
                      decline_insurance: false
                    }
                    await this.$store.dispatch( 'onBoardingStore/declineInsurance', data);
                    tableRateWithCoverageValue && this.$store.commit('onBoardingStore/updateLeaseField', {
                        key: 'table_rate_coverage_amount', 
                        value: this.coverage_value 
                    });
                } else {
                    await this.$store.dispatch('onBoardingStore/deleteInsurance', null);
                }
                EventBus.$emit('updatePaymentDetails');
                EventBus.$emit('updatePartialPaymentAmount');
                this.setInsuranceForm();
                this.stopLoading(this.$options.name)
              } catch(err){
                this.stopLoading(this.$options.name)
              }
            },
            async saveInsurance(insurance){
                if(!insurance) return;

                var data = {
                    insurance_id: insurance.id
                };
                if(this.table_rate_insurance && this.coverage_value) {
                  data.coverage_amount = this.coverage_value;
                }

                await this.$store.dispatch('onBoardingStore/saveInsurance', data);
                await this.$store.dispatch('onBoardingStore/getServices');
                await this.$store.dispatch('onBoardingStore/getEstimate', {updateInvoice: true, generateInvoices: true});
                
            },
            updateCoverageValue(coverageValue){
              this.coverage_value = coverageValue;
              if(this.insurance_form) this.selectInsurance();
            }
        }
    }
</script>

<style scoped>

  .insurance-container{
    border: 1px solid #C4CDD5;
    background: white;
    padding: 30px;
    border-radius: 4px;
  }
  .policy-container{
    padding-top: 0;
    padding-bottom: 0;
  }
  .form-label{
    background: #F4F6F8;
    border-radius: 4px;
  }
  .insurance-option-row{
    border-bottom: 1px solid #C4CDD5;
    padding: 10px 20px;
  }
  .insurance-section-label{
    padding: 30px;
    font-weight: 500;
  }
  .insurance-container{
    padding: 30px 50px;
  }

  .insurance-container .v-input--radio-group--column{
    margin: 0;
  }
  .insurance-container .insurance-row {
    margin-bottom: 10px;

  }
  .insurance-option-row.no-border{
    border-bottom: none;
  }


</style>




<!--<template>-->
<!--    <div>-->
<!--      <status @resetStatus="errorClear($options.name)" v-if="errorHas($options.name)" :message="errorGet($options.name)" status="error"></status>-->
<!--        <h2 class="headline mb-1">Select Insurance Coverage</h2><br />-->
<!--        <status @resetStatus="errorClear($options.name)" v-if="errorHas($options.name)" :message="errorGet($options.name)" status="error"></status>-->

<!--        <v-card class="mb-5">-->
<!--          <v-card-text>-->
<!--            <v-radio-group v-model="insurance" @change="selectInsurance" hide-details class="mt-0">-->
<!--              <v-container class="pt-0">-->
<!--                <v-row>-->
<!--                  <v-col cols="3" style="background-color: #F4F6F8;">-->
<!--                    <v-container>-->
<!--                      <v-row no-gutters>-->
<!--                        <v-col><h2 class="subtitle-2 font-weight-medium">Coverage</h2></v-col>-->
<!--                      </v-row>-->
<!--                    </v-container>-->
<!--                  </v-col>-->

<!--                  <v-col cols="9" class="py-0">-->
<!--                      <v-container>-->
<!--                        <div v-for="(o, i) in optionalInsurance" :key="o.id">-->
<!--                          <v-row>-->
<!--                            <v-col class="row-checkbox-col">-->
<!--                              <v-radio class="ma-0 pa-0" :value="o" :name="o.id" :id="o.id"></v-radio>-->
<!--                            </v-col>-->
<!--                            <v-col :label-for="o.id">-->
<!--                              <strong>{{o.Insurance.name}}</strong><br />-->
<!--                              {{o.Insurance.description}}-->
<!--                            </v-col>-->
<!--                          </v-row>-->
<!--                          <v-divider></v-divider>-->
<!--                        </div>-->

<!--                        <div v-if="!optionalInsurance.length" >-->
<!--                          <v-row>-->
<!--                            <v-col>-->
<!--                              No Insurance Options Found-->
<!--                            </v-col>-->
<!--                          </v-row>-->
<!--                          <v-divider></v-divider>-->
<!--                        </div>-->
<!--                      </v-container>-->

<!--                  </v-col>-->
<!--                </v-row>-->
<!--              </v-container>-->
<!--              <v-container class="pt-0">-->
<!--                <v-row >-->
<!--                  <v-col cols="3" style="background-color: #F4F6F8;">-->
<!--                    <v-container>-->
<!--                      <v-row no-gutters>-->
<!--                        <v-col><h2 class="subtitle-2 font-weight-medium">Decline Insurance</h2></v-col>-->
<!--                      </v-row>-->
<!--                    </v-container>-->
<!--                  </v-col>-->
<!--                  <v-col cols="9">-->
<!--                    <v-container>-->
<!--                      <div>-->
<!--                        <v-row>-->
<!--                          <v-col class="row-checkbox-col">-->
<!--                            <v-radio class="ma-0 pa-0" :value="0" name="decline" id="decline"></v-radio>-->
<!--                          </v-col>-->
<!--                          <v-col >-->
<!--                            <label for="decline"><strong>Tenant has their own insurance</strong></label>-->
<!--                          </v-col>-->
<!--                        </v-row>-->
<!--                      </div>-->
<!--                    </v-container>-->
<!--                  </v-col>-->
<!--                </v-row>-->
<!--              </v-container>-->
<!--            </v-radio-group>-->

<!--&lt;!&ndash;              <v-container class="pt-0">&ndash;&gt;-->
<!--&lt;!&ndash;                <v-row >&ndash;&gt;-->
<!--&lt;!&ndash;                  <v-col cols="3" style="background-color: #F4F6F8;">&ndash;&gt;-->
<!--&lt;!&ndash;                    <v-container>&ndash;&gt;-->
<!--&lt;!&ndash;                      <v-row no-gutters>&ndash;&gt;-->
<!--&lt;!&ndash;                        <v-col><h2 class="subtitle-2 font-weight-medium">Insurance Brochure</h2></v-col>&ndash;&gt;-->
<!--&lt;!&ndash;                      </v-row>&ndash;&gt;-->
<!--&lt;!&ndash;                    </v-container>&ndash;&gt;-->
<!--&lt;!&ndash;                  </v-col>&ndash;&gt;-->
<!--&lt;!&ndash;                  <v-col cols="9">&ndash;&gt;-->
<!--&lt;!&ndash;                    <v-container>&ndash;&gt;-->
<!--&lt;!&ndash;                      <div>&ndash;&gt;-->
<!--&lt;!&ndash;                        <v-row>&ndash;&gt;-->
<!--&lt;!&ndash;                          <v-col align-self="center" class="row-checkbox-col">&ndash;&gt;-->
<!--&lt;!&ndash;                            <v-checkbox class="ma-0 pa-0"  hide-details v-model="email_brochure" name="email_brochure" id="email_brochure"></v-checkbox>&ndash;&gt;-->
<!--&lt;!&ndash;                          </v-col>&ndash;&gt;-->
<!--&lt;!&ndash;                          <v-col align-self="center" style="flex: 0 1 160px">&ndash;&gt;-->
<!--&lt;!&ndash;                            <label for="email_brochure"><strong>Email Brochure</strong></label>&ndash;&gt;-->
<!--&lt;!&ndash;                          </v-col>&ndash;&gt;-->
<!--&lt;!&ndash;                          <v-col align-self="center" v-show="email_brochure">&ndash;&gt;-->
<!--&lt;!&ndash;                            <v-text-field&ndash;&gt;-->
<!--&lt;!&ndash;                              v-model="email_brochure_to"&ndash;&gt;-->
<!--&lt;!&ndash;                              dense&ndash;&gt;-->
<!--&lt;!&ndash;                              hide-details&ndash;&gt;-->
<!--&lt;!&ndash;                              label="Email Address"&ndash;&gt;-->
<!--&lt;!&ndash;                            >&ndash;&gt;-->
<!--&lt;!&ndash;                              <template slot="append"><v-btn text color="primary">Send</v-btn></template>&ndash;&gt;-->
<!--&lt;!&ndash;                            </v-text-field>&ndash;&gt;-->
<!--&lt;!&ndash;                          </v-col>&ndash;&gt;-->
<!--&lt;!&ndash;                        </v-row>&ndash;&gt;-->
<!--&lt;!&ndash;                        <v-row>&ndash;&gt;-->
<!--&lt;!&ndash;                          <v-col align-self="center" class="row-checkbox-col">&ndash;&gt;-->
<!--&lt;!&ndash;                            <v-checkbox class="mt-0 pt-0"  hide-details v-model="text_brochure" name="text_brochure" id="text_brochure"></v-checkbox>&ndash;&gt;-->
<!--&lt;!&ndash;                          </v-col>&ndash;&gt;-->
<!--&lt;!&ndash;                          <v-col align-self="center" style="flex: 0 1 160px" >&ndash;&gt;-->
<!--&lt;!&ndash;                            <label for="text_brochure"><strong>Text Brochure</strong></label>&ndash;&gt;-->
<!--&lt;!&ndash;                          </v-col>&ndash;&gt;-->
<!--&lt;!&ndash;                          <v-col align-self="center" v-show="text_brochure">&ndash;&gt;-->
<!--&lt;!&ndash;                            <v-text-field&ndash;&gt;-->
<!--&lt;!&ndash;                              v-model="text_brochure_to"&ndash;&gt;-->
<!--&lt;!&ndash;                              dense&ndash;&gt;-->
<!--&lt;!&ndash;                              hide-details&ndash;&gt;-->
<!--&lt;!&ndash;                              label="Phone Number"&ndash;&gt;-->
<!--&lt;!&ndash;                            >&ndash;&gt;-->
<!--&lt;!&ndash;                              <template slot="append"><v-btn text color="primary">Send</v-btn></template>&ndash;&gt;-->
<!--&lt;!&ndash;                            </v-text-field>&ndash;&gt;-->
<!--&lt;!&ndash;                          </v-col>&ndash;&gt;-->
<!--&lt;!&ndash;                        </v-row>&ndash;&gt;-->
<!--&lt;!&ndash;                        <v-row>&ndash;&gt;-->
<!--&lt;!&ndash;                          <v-col align-self="center" class="row-checkbox-col">&ndash;&gt;-->
<!--&lt;!&ndash;                            <v-checkbox class="mt-0 pt-0"  hide-details v-model="print_brochure" name="print_brochure" id="print_brochure"></v-checkbox>&ndash;&gt;-->
<!--&lt;!&ndash;                          </v-col>&ndash;&gt;-->
<!--&lt;!&ndash;                          <v-col align-self="center">&ndash;&gt;-->
<!--&lt;!&ndash;                            <label for="print_brochure"><strong>Print Brochure</strong></label>&ndash;&gt;-->
<!--&lt;!&ndash;                          </v-col>&ndash;&gt;-->
<!--&lt;!&ndash;                          <v-col align-self="center" v-show="print_brochure" style="flex: 1" class="align-right">&ndash;&gt;-->
<!--&lt;!&ndash;                            <v-btn color="primary" text>Print</v-btn>&ndash;&gt;-->
<!--&lt;!&ndash;                          </v-col>&ndash;&gt;-->
<!--&lt;!&ndash;                        </v-row>&ndash;&gt;-->
<!--&lt;!&ndash;                      </div>&ndash;&gt;-->
<!--&lt;!&ndash;                    </v-container>&ndash;&gt;-->
<!--&lt;!&ndash;                  </v-col>&ndash;&gt;-->
<!--&lt;!&ndash;                </v-row>&ndash;&gt;-->
<!--&lt;!&ndash;              </v-container>&ndash;&gt;-->

<!--          </v-card-text>-->
<!--        </v-card>-->

<!--    </div>-->
<!--</template>-->

<!--<script type="text/babel">-->

<!--    import api from '../../../assets/api.js';-->
<!--    import Status from '../../includes/Messages.vue';-->
<!--    import Loader from '../../assets/CircleSpinner.vue';-->
<!--    import moment from 'moment';-->
<!--    import { mapGetters, mapActions, mapMutations } from 'vuex';-->

<!--    export default {-->
<!--        name: "ConfigureInsurance",-->
<!--        data() {-->
<!--            return {-->
<!--                services:[],-->
<!--                taxRate: 0,-->
<!--                showAddService:false,-->
<!--                showDeleteService:false,-->
<!--                showAddProduct:false,-->
<!--                selectedService: null,-->
<!--                offset: 0,-->
<!--                service_updated: false,-->
<!--                insurance: {},-->
<!--                email_brochure: false,-->
<!--                email_brochure_to: '',-->
<!--                text_brochure: false,-->
<!--                text_brochure_to: '',-->
<!--                print_brochure: '',-->

<!--            }-->
<!--        },-->
<!--        components:{-->
<!--            Status,-->
<!--            Loader-->
<!--        },-->
<!--        created (){-->

<!--          if(this.lease.decline_insurance){-->
<!--              this.insurance = 0;-->
<!--          } else {-->
<!--              this.insurance = this.optionalInsurance.find(i => i.product_id === this.insurance.product_id ) ;-->

<!--          }-->

<!--        },-->
<!--        filters:{-->
<!--            lineTotal(c){-->
<!--                return c.qty * c.cost;-->
<!--            },-->
<!--            getEndOfPeriod(date){-->
<!--                return moment(date).add(1, 'month').subtract(1, 'day');-->
<!--            }-->
<!--        },-->
<!--        computed: {-->
<!--            canProgress(){-->
<!--                if(this.declineInsurance || this.$store.getters.leaseInsurance.id){-->
<!--                    return true;-->
<!--                }-->
<!--                return false;-->
<!--            },-->
<!--            declineInsurance: {-->
<!--                get() { return this.lease.decline_insurance; },-->
<!--                set(value) { this.$store.commit('onBoardingStore/updateLeaseField', { key: 'decline_insurance', value: value}); },-->
<!--            },-->

<!--            optionalInsurance(){-->
<!--                if(!this.template) return [];-->
<!--                return this.template.Services.filter(s => s.service_type === 'insurance' && s.optional);-->
<!--            },-->
<!--            ...mapGetters({-->
<!--                property: 'onBoardingStore/property',-->
<!--                properties: 'propertiesStore/filtered',-->
<!--                template: 'onBoardingStore/template',-->
<!--                lease: 'onBoardingStore/lease',-->
<!--                tenants: 'onBoardingStore/tenants',-->
<!--            }),-->

<!--        },-->
<!--        methods: {-->
<!--            insurancePrice(insurance){-->
<!--                var premium;-->
<!--                if(insurance.Insurance.premium_type === "%"){-->
<!--                    premium = this.$store.getters.leaseRent * (insurance.Insurance.premium_value / 100);-->
<!--                } else {-->
<!--                    premium = insurance.Insurance.premium_value;-->
<!--                }-->
<!--                return premium; //(Math.round(premium * 1e2) / 1e2).toFixed(2);-->
<!--            },-->
<!--            async selectInsurance(o){-->
<!--                if(o === 0){-->
<!--                    this.$store.commit('updateDeclineInsurance', true);-->
<!--                    this.$store.commit('setInsurance', null);-->
<!--                    await api.put(this, api.LEASES + this.$store.getters.lease.id + '/decline-insurance/', {-->
<!--                        decline_insurance: this.$store.getters.lease.decline_insurance-->
<!--                    });-->
<!--                    // return return await this.updateInsurance();-->
<!--                }-->
<!--                if(o.product_id !== this.$store.getters.leaseInsurance.product_id){-->
<!--                    this.service_updated = true;-->
<!--                    await this.saveInsurance(o);-->
<!--                    this.$store.commit('updateDeclineInsurance', false);-->
<!--                    await api.put(this, api.LEASES + this.$store.getters.lease.id + '/decline-insurance/', {-->
<!--                        decline_insurance: false-->
<!--                    });-->
<!--                    // this.updateInsurance();-->
<!--                } else {-->
<!--                    await this.deleteInsurance();-->
<!--                }-->
<!--            },-->
<!--            async deleteInsurance(){-->
<!--                this.$store.commit('setInsurance', null);-->
<!--                await api.delete(this, api.LEASES + this.$store.getters.lease.id + '/template-insurance/');-->
<!--            },-->
<!--            async saveInsurance(insurance){-->
<!--                if(!insurance) return;-->
<!--                this.$store.commit('updateDeclineInsurance', false);-->
<!--                insurance.Insurance.prorate = insurance.prorate;-->
<!--                insurance.Insurance.prorate_out = insurance.prorate_out;-->
<!--                insurance.Insurance.recurring = insurance.recurring;-->
<!--                this.$store.commit('setInsurance', insurance.Insurance);-->

<!--                var data = {-->
<!--                    insurance_id: insurance.id-->
<!--                };-->
<!--                await api.post(this, api.LEASES + this.$store.getters.lease.id + '/template-insurance/', data);-->
<!--                await this.$store.dispatch('getEstimate');-->
<!--            },-->
<!--            async updateInsurance(){-->
<!--                this.$store.commit('updateDeclineInsurance', !this.$store.getters.lease.decline_insurance);-->
<!--                if(this.$store.getters.lease.decline_insurance) {-->
<!--                    await this.deleteInsurance();-->
<!--                }-->
<!--                await api.put(this, api.LEASES + this.$store.getters.lease.id + '/decline-insurance/', {-->
<!--                    decline_insurance: this.$store.getters.lease.decline_insurance-->
<!--                });-->

<!--                await this.$store.dispatch('getEstimate');-->
<!--            }-->
<!--        }-->
<!--    }-->
<!--</script>-->

<!--<style scoped>-->

<!--    .insurance-container{-->
<!--        border: 1px solid #C4CDD5;-->
<!--        background: white;-->
<!--        padding: 30px;-->
<!--        border-radius: 4px;-->
<!--    }-->
<!--    .policy-container{-->
<!--        padding-top: 0;-->
<!--        padding-bottom: 0;-->
<!--    }-->
<!--    .form-label{-->
<!--        background: #F4F6F8;-->
<!--        border-radius: 4px;-->
<!--    }-->
<!--    .insurance-option-row{-->
<!--        border-bottom: 1px solid #C4CDD5;-->
<!--        padding: 10px 20px;-->
<!--    }-->
<!--    .insurance-section-label{-->
<!--        padding: 30px;-->
<!--        font-weight: bold;-->
<!--    }-->
<!--    .insurance-container{-->
<!--        padding: 30px 50px;-->
<!--    }-->

<!--    .insurance-container .v-input&#45;&#45;radio-group&#45;&#45;column{-->
<!--        margin: 0;-->
<!--    }-->
<!--    .insurance-container .insurance-row {-->
<!--        margin-bottom: 10px;-->

<!--    }-->
<!--    .insurance-option-row.no-border{-->
<!--        border-bottom: none;-->
<!--    }-->


<!--</style>-->
