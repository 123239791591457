<template>
    <editor :value="value" :api-key="apiKey" :init="editorConfig" @input="updateValue" />
</template>

<script>
import Editor from '@tinymce/tinymce-vue';

export default {
    name: "MceEditor",
    data() {
        return {
            apiKey: process.env.VUE_APP_TINYMCE_FREE_API_KEY
        }
    },
    components: { Editor },
    props: {
        value: String,
        height: {
            type: Number,
            default: 700,
        },
        plugins: {
            type: Array,
            default: () => [
                'autolink fullpage lists link image anchor preview',
                'code',
                'insertdatetime media table paste code wordcount noneditable importcss',
            ],
        },
        // Possible values: true, false, or string of toolbar
        toolbar: {
            type: [Boolean, String],
        },
        // Possible values: true, false, or string of menus
        menubar: {
            type: [Boolean, String],
            default: false,
        },
        // Possible values: true, false, 'both'
        resize: {
            type: [Boolean, String],
            default: true
        },
        // Type: String
        removed_menuitems:{
            type: String,
            default: 'inserttable,cell,row,column,tableprops,deletetable'
        }
    },
    computed: {
        editorConfig() {
            return {
                image_title: true,
                block_unsupported_drop: true,
                file_picker_types: 'image',
                file_picker_callback: this.handleFilePicker,
                height: this.height,
                branding: false,
                elementpath: false,
                element_format: 'html',
                noneditable_noneditable_class: 'mceNonEditable',
                editable_class: 'mceEditable',
                custom_elements: 'style, head , meta, html , title',
                object_resizing: 'img',
                table_sizing_mode: 'responsive',
                invalid_elements: 'script',
                removed_menuitems : this.removed_menuitems,
                menu: { file: { title: 'File', items: 'preview' }},
                menubar: this.menubar,
                plugins: this.plugins,
                toolbar: this.toolbar,
                setup: this.editorSetup,
                // content_style: '.mceNonEditable { border: 3px solid red; padding: 8px; }',
            };
        },
    },
    methods: {
        updateValue(newValue) {
            this.$emit('input', newValue);
        },
        handleFilePicker(cb, value, meta) {
            const input = document.createElement('input');
            input.setAttribute('type', 'file');
            input.setAttribute('accept', 'image/*');

            input.addEventListener('change', (e) => {
                const file = e.target.files[0];
                const reader = new FileReader();
                reader.addEventListener('load', () => {
                    const id = 'blobid' + new Date().getTime();
                    const blobCache = tinymce.activeEditor.editorUpload.blobCache;
                    const base64 = reader.result.split(',')[1];
                    const blobInfo = blobCache.create(id, file, base64);
                    blobCache.add(blobInfo);
                    cb(blobInfo.blobUri(), { title: file.name });
                });
                reader.readAsDataURL(file);
            });
            input.click();
        },
        editorSetup(editor) {
            editor.on('init', (event) => {
                // editor.getBody().querySelectorAll('[contenteditable="false"]').forEach(el => {
                //     el.classList.add('mceNonEditable');
                // });
            });
        },
    },
};
</script>